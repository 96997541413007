import React from 'react';
import { tokens } from '@fluentui/tokens';
import { renderToStaticMarkup } from 'react-dom/server';

import { kustoEntityTypes } from '@kusto/app-common';
import * as kusto from '@kusto/client';

import { clipboardStyle } from '../common';
import { EntityType } from '../common/types';
import { KustoEditorHandleContainer } from '../core/coreDefaultSetup';
import { quoteIfNeeded } from './cslCommandGenerator';

/**
 * Generate an HTML table from query table-result data to be pasted into rich-text editor (email).
 *
 * @param {kusto.KustoResult} tableResult Source data to generate table from.
 * @param {string} title A title to include above the table.
 */
export function generateQueryResultsHtml(tableResult: kusto.KustoResult, title?: string) {
    const { columns, rows } = tableResult;
    if (!columns || !rows) {
        return undefined;
    }

    const tableHeader = columns.map((col, i) => (
        <th style={{ border: 'solid black 1.0pt', whiteSpace: 'nowrap' }} key={i}>
            {col.headerName}
        </th>
    ));

    const tableRows = rows.map((row, i) => (
        <tr key={i}>
            {columns.map((col, indx) => {
                const data = row[col.field];
                const localizedData =
                    /* for some reason renderToStaticMarkup prints empty strings for boolean values, so we're taking
                    care here to convert to string if that's the case.*/
                    typeof data === 'boolean'
                        ? data.toString()
                        : typeof data === 'number'
                        ? // display number in locale to make reading them easier
                          data.toLocaleString()
                        : data;
                return (
                    <td
                        style={{
                            border: 'solid black 1.0pt',
                            whiteSpace: 'nowrap',
                        }}
                        key={indx}
                    >
                        {localizedData}
                    </td>
                );
            })}
        </tr>
    ));

    const reactTableElement = (
        <div>
            <br />
            {title && <div style={clipboardStyle}>{title}</div>}
            <table
                cellPadding="5"
                cellSpacing="1"
                style={{
                    border: '1',
                    borderColor: 'Black',
                    fontFamily: tokens.fontFamilyBase,
                    fontSize: 12,
                    borderCollapse: 'collapse',
                }}
            >
                <tr style={{ backgroundColor: 'DarkGray' }}>{tableHeader}</tr>
                {tableRows}
            </table>
        </div>
    );

    return renderToStaticMarkup(reactTableElement);
}

/**
 * Generate a simple plain-text table from table result data to be pasted into plain-text editor (notepad).
 *
 * @param {kusto.KustoResult} tableResult Source data to generate table from.
 * @param {boolean} includeHeader Whether to include the columns row to the created table.
 * @param {string} cellDelimiter Delimiter to use between cells. Default is tab space.
 */
export function generateQueryResultsPlainText(
    tableResult: kusto.KustoResult,
    includeHeader = true,
    cellDelimiter = '\t'
) {
    const { columns, rows } = tableResult;
    if (!columns || !rows) {
        return undefined;
    }

    /*
    columns = [{ headerName: 'a', ... }, { headerName: 'b' ...]
    headerNames = ['a', 'b', ..]
    rows = [ { 'a': 1, 'b': 2, 'c': 3 }, ...]
    */

    const headerNames = columns.map((column) => column.headerName);
    const headerLine = headerNames.join(cellDelimiter);
    const dataLines = rows.map((row) => headerNames.map((headerName) => row[headerName]).join(cellDelimiter));

    const tableLines = includeHeader ? [headerLine, ...dataLines] : dataLines;

    return tableLines.join('\n');
}

export function generateErrorHtml(errorMessage: string): string {
    const element = (
        <div
            style={{
                backgroundColor: '#fde7e9',
                color: '#333333',
                whiteSpace: 'pre',
            }}
        >
            {errorMessage}
        </div>
    );

    return renderToStaticMarkup(element);
}

/**
 * Generate a HTML from chart canvas to be pasted into rich-text editor (like email).
 * @param {HTMLCanvasElement} chartCanvas Source chart canvas to generate HTML from. Pass the `tab.canvasToPaste` here.
 */
export function generateChartHtml(chartCanvas?: HTMLCanvasElement): string | undefined {
    if (!chartCanvas) {
        return;
    }

    const image = new Image();
    image.src = chartCanvas.toDataURL('image/png');

    const chartHtml = new XMLSerializer().serializeToString(image);

    return chartHtml;
}

export const getContentToPasteByKustoEntityTypes = (entityType: string, content: string): string | undefined => {
    switch (entityType) {
        case kustoEntityTypes.noIconType:
        case kustoEntityTypes.group:
        case kustoEntityTypes.folder:
        case kustoEntityTypes.favorites:
        case kustoEntityTypes.tableFolder:
        case kustoEntityTypes.functionFolder:
        case kustoEntityTypes.externalTableFolder:
        case kustoEntityTypes.materializedViewTableFolder:
            return;
        case 'dynamic':
        case 'datetime':
        case 'timespan':
        case 'guid':
        case 'long':
        case 'string':
        case 'real':
        case 'boolean':
        case 'int':
            // Using a regular expression to match and remove the (columnType) part
            return content.replace(/\s*\([^)]*\)\s*/, '');
        case EntityType.Table:
            return quoteIfNeeded(content) + '\n| ';

        case EntityType.ExternalTable:
            return `external_table("${content}")\n| `;
        default:
            return content;
    }
};

export const setPasteTextIntoTheEditor = (text: string, kustoEditor: KustoEditorHandleContainer) => {
    const handle = kustoEditor.ref;
    if (!handle) {
        return;
    }
    handle.pasteText(text, 'cursorPosition');
};
