import { CloudSettings } from 'azure-kusto-data';
import type { CloudInfo } from 'azure-kusto-data';

import { IKweTelemetry } from '@kusto/utils';

const RESOURCE_ENDPOINT_NOT_AVAILABLE = 'null';

// This is unfortunately a hack, because the CloudSettings always returns the defaultCloudInfo
// and we don't have a way to know if the response is the fallback or the actual response.
// Since this function can be used by multiple apps (KWE, ibiza, Fabric, etc.), we can't just set a default fallback that fits all.
// It needs to be passed as an argument, and used only in case the response equals this weird null object.
CloudSettings.defaultCloudInfo = {
    LoginEndpoint: 'null',
    LoginMfaRequired: false,
    KustoClientAppId: 'null',
    KustoClientRedirectUri: 'null',
    KustoServiceResourceId: RESOURCE_ENDPOINT_NOT_AVAILABLE,
    FirstPartyAuthorityUrl: 'null',
};

export async function getClusterScopesFromAuthMetadata({
    clusterUrl,
    telemetry,
    defaultScope,
}: {
    clusterUrl: string;
    telemetry: IKweTelemetry;
    defaultScope?: string[];
}): Promise<string[] | undefined> {
    try {
        const cloudInfo: CloudInfo = await CloudSettings.getCloudInfoForCluster(clusterUrl);
        if (cloudInfo.KustoServiceResourceId === RESOURCE_ENDPOINT_NOT_AVAILABLE) {
            telemetry.trace('getCloudInfoForCluster for cluster returned fallback data. Returning default scope', {
                clusterUrl,
                defaultScope,
            });
            return defaultScope;
        }
        telemetry.trace('getScope for cluster returned cloudInfo successfully', { clusterUrl, cloudInfo });
        return [`${cloudInfo.KustoServiceResourceId}/.default`];
    } catch (error) {
        telemetry.exception('getScope for cluster failed. Returning default scope', {
            clusterUrl,
            defaultScope,
            exception: error,
        });
        return defaultScope;
    }
}
