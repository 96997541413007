import React, { useCallback } from 'react';
import { Checkbox, CheckboxProps } from '@fluentui/react-components';
import { Path } from 'react-hook-form';

import { RHFField, RHFFieldProps, TFormFieldsBase } from './RHFField';

export interface RHFCheckboxProps<TFormFields extends TFormFieldsBase, TFieldName extends Path<TFormFields>>
    extends Omit<RHFFieldProps<TFormFields, TFieldName>, 'Component'> {
    /** Props of the `<Checkbox />` */
    checkboxProps?: CheckboxProps;
}

/**
 * Fluent `<Checkbox />` nested in `<Field />` wrapped inside React-Hook-Form `<Controller />`.
 * Used to support validation and error messages using the RHF library.
 */
export const RHFCheckbox = <TFormFields extends TFormFieldsBase, TFieldName extends Path<TFormFields>>({
    controllerProps,
    fieldProps,
    checkboxProps,
}: RHFCheckboxProps<TFormFields, TFieldName>) => {
    const checkboxComponent = useCallback<RHFFieldProps<TFormFields, TFieldName>['Component']>(
        ({ field: rhfField }) => (
            <Checkbox
                // Without `defaultChecked` the state is `checked` when reading the `formState` but the checkbox isn't rendered with a checkmark.
                // Some sort of weirdness between Fluent's <Checkbox /> and React-Hook-Form. I guess that's because Fluent uses `checked` prop while RHF uses `value`.
                defaultChecked={rhfField.value}
                {...rhfField}
                {...checkboxProps}
            />
        ),
        [checkboxProps]
    );

    return <RHFField controllerProps={controllerProps} fieldProps={fieldProps} Component={checkboxComponent} />;
};
