import React from 'react';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';

import { ExceptionBoundary } from '@kusto/app-common';
import { RenderHelperProvider } from '@kusto/ui-components';
import { TelemetryProvider } from '@kusto/utils';

import { DataExplorationContext, dataExplorationContext } from '../context/DataExplorationContext';
import { Chart } from './components/Chart/Chart';
import { PillsBar } from './components/PillsBar/PillsBar';
import { Results } from './components/Results/Results';
import { SchemaColumns } from './components/SchemaColumns/SchemaColumns';
import { DataExplorationToolbar, ToolbarItem } from './components/Toolbar/DataExplorationToolbar';
import { DataExplorationStoreProvider } from './store/DataExplorationStore';
import { VisualState } from './store/state';

import styles from './DataExploration.module.scss';

export interface DataExplorationProps {
    baseQuery: string;
    visual: VisualState;
    toolbar?: ToolbarItem[];
    context: DataExplorationContext;
}

const DataExplorationRender: React.FunctionComponent<Pick<DataExplorationProps, 'toolbar'>> = ({ toolbar }) => {
    return (
        <div className={styles.dataExploration}>
            <DataExplorationToolbar buttons={toolbar} />
            <div className={styles.dataExplorationCard}>
                <div className={styles.mainSection}>
                    <div className={styles.pillsSection} data-testid="data-expo-pills-bar">
                        <PillsBar />
                    </div>
                    <SplitPane split="horizontal">
                        <Pane initialSize="50%" minSize="25%">
                            <div className={styles.splitPanePanel} data-testid="data-expo-chart">
                                <Chart />
                            </div>
                        </Pane>
                        <Pane minSize="25%">
                            <div className={styles.splitPanePanel} data-testid="data-expo-results">
                                <Results />
                            </div>
                        </Pane>
                    </SplitPane>
                </div>
                <div className={styles.rightPane} data-testid="data-expo-columns-info">
                    <SchemaColumns />
                </div>
            </div>
        </div>
    );
};

export const WrapInContext: React.FunctionComponent<DataExplorationProps> = ({
    context,
    baseQuery,
    visual,
    children,
}) => (
    <TelemetryProvider telemetry={context.telemetry}>
        <ExceptionBoundary
            cidPrefix="DEXP"
            area="Explore Data crash"
            supportEmail={context.supportEmail}
            t={context.t.utils.components.crashText}
            wrapError={(e) => e}
            headerLevel={2}
        >
            <RenderHelperProvider>
                <dataExplorationContext.Provider value={context}>
                    <DataExplorationStoreProvider baseQuery={baseQuery} visual={visual}>
                        {children}
                    </DataExplorationStoreProvider>
                </dataExplorationContext.Provider>
            </RenderHelperProvider>
        </ExceptionBoundary>
    </TelemetryProvider>
);

export const DataExploration: React.FunctionComponent<DataExplorationProps> = ({
    context,
    baseQuery,
    visual,
    toolbar,
}) => (
    <WrapInContext context={context} baseQuery={baseQuery} visual={visual}>
        <DataExplorationRender toolbar={toolbar} />
    </WrapInContext>
);
