import React from 'react';

import type { IKustoClient, KustoClientLocale, KustoDomains } from '@kusto/client';
import { KweException, type IKweTelemetry, type Theme } from '@kusto/utils';
import type { KweVisualFwkLocale, ParsedVisuals, VisualConfig } from '@kusto/visual-fwk';

import { DataExplorationFeatureFlags } from '../DataExplorationModal/types/FeatureFlags';

// This throw TS error because 'strings.json' imported twice, once here and again in fakeContext (mock)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export type DataExplorationStrings = typeof import('../../strings.json');

export interface DataExplorationLocale extends KweVisualFwkLocale, KustoClientLocale {
    readonly dataExploration: DataExplorationStrings;
}

export interface DataExplorationContext {
    readonly clusterUrl: string;
    readonly databaseName: string;
    readonly kustoClient: IKustoClient;
    readonly kustoDomains: KustoDomains;
    readonly telemetry: IKweTelemetry;
    readonly supportEmail: string;
    readonly t: DataExplorationLocale;
    readonly theme: Theme;
    readonly parsedVisuals: ParsedVisuals;
    readonly visualsLayout: VisualConfig['layout'];
    readonly featureFlags?: DataExplorationFeatureFlags;
}

export const dataExplorationContext = React.createContext<undefined | DataExplorationContext>(undefined);

export function useDataExplorationContext() {
    const context = React.useContext(dataExplorationContext);
    if (context === undefined) {
        throw new KweException('useDataExplorationContext can only be called after the context is initialized');
    }

    return context;
}

export function DataExplorationContextProvider({
    children,
    context,
}: {
    children: React.ReactNode;
    context: DataExplorationContext;
}) {
    return <dataExplorationContext.Provider value={context}>{children}</dataExplorationContext.Provider>;
}
