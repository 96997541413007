import * as mobx from 'mobx';

import {
    DeletedFlags,
    FeatureFlagList,
    FeatureFlagsService,
    iterFlagsList,
    mergeFeatureFlagLists,
    mergeFlagsConfig,
    TypeFromFlagList,
    type FeatureFlags,
    type FeatureFlagsSetting,
} from '@kusto/app-common';
import { dashboardFlags, dashboardsUrlFeatureFlags } from '@kusto/dashboard';
import { QueryFeatureFlag, queryFeatureFlags } from '@kusto/query';
import { visualFwkFlags } from '@kusto/visual-fwk';

import { KWE_CONSTANTS, KWE_ENV } from '../common/constants/constants';
import { ibizaFeatureFlags } from '../common/featureFlags/ibiza';
import { iFrameFeatureFlags } from '../common/featureFlags/iframe';
import { kweSiteFeatureFlags } from '../common/featureFlags/kweSite';

export type FeatureFlag = TypeFromFlagList<typeof featureFlags>;

/**
 * These flags are intentionally duplicated from the query area so we can add
 * them to {@link legacyFeatureFlags}
 *
 * @deprecated No reason to add to this list
 */
const legacyQueryFeatureFlags = [
    'ShowConnectionButtons',
    'RefreshConnection',
    'ShowPageHeader',
] as const satisfies readonly QueryFeatureFlag[];

/**
 * Legacy feature flags support getting set in the url without the f- prefix
 * @deprecated Use {@link urlFeatureFlags} array instead
 *
 * Separate from {@link legacyFeatureFlags} so we can include it in
 * {@link urlFeatureFlags} without including query flags twice.
 */
const baseLegacyFeatureFlags = {
    Debug: ['consoleLogs'],
    Query: [
        'ShowShareMenu',
        'ShowOpenNewWindowButton',
        'ShowFileMenu',
        'ShowPersona',
        'IFrameAuth',
        'PersistAfterEachRun',
        'ShowSmoothIngestion',
        'RefreshConnection',
        'HideConnectionPane',
    ],
    Dashboards: [
        /** Block access to dashboards and hides the left vertical navigation bar (which also includes One-Click ingestion) */
        'EnableDashboards',
    ],
    ['Virtual cluster']: ['VirtualCluster', 'VirtualClusterUpgrade', 'VirtualClusterEventHub'],
    KWE: [
        'ShowPageHeader',
        [
            'UseIFrameSharing',
            'Requires ShowShareMenu and IFrameAuth also be set. Requires `origin` query param (i.e. `origin=https://portal.microsoftgeneva.com/`) to verify trusted source. The Share Menu will be displayed as a single Share button that uses `postMessage` to send relevant query details to the parent hosting the IFRAME.',
        ],
        'ibizaPortal',
    ],
} as const satisfies FeatureFlagList;

/**
 * Legacy feature flags support getting set in the url without the f- prefix
 * @deprecated Use {@link featureFlags array instead}
 *
 * Use this to check if we can remove stuff from this list:
 * ```kql
 * customEvents
 * | where name == 'KWE flag set without prefix'
 * | extend kustoweb_flags = parse_json(tostring(parse_json(customDimensions['flags'])))
 * | mv-expand kustoweb_flag=kustoweb_flags
 * | summarize count() by tostring(kustoweb_flag)
 * ```
 */
export const legacyFeatureFlags = [...legacyQueryFeatureFlags, ...iterFlagsList(baseLegacyFeatureFlags)];

export const kweFeatureFlags = {} as const satisfies FeatureFlagList;

/**
 * Only put flags here if they don't have any security implications
 */
export const urlFeatureFlags = mergeFeatureFlagLists(
    // TODO(feature-flags): Organize
    {
        Debug: [['ducks', '🐥🐥🐥🐥🐥'], 'verboseLogs'],
        Query: [
            ...queryFeatureFlags,
            'Homepage',
            'EnableGetData',
            'EnableDataProfile',
            'EnableAddTable',
            // UBS is asking to keep in the Copy context menu only the Open In Excel option
            'KeepOnlyOpenInExcelOption',
            'SyncBrowserTabs',
            'HideTabs',
            'ConnectionPaneUsingFluentV9',
            'EnableConnectionPaneGroups',
            'EnableCreateTableWizard',
            'FindMyPartner',
            'AddHelpClusterOnEmptyState',
            'EnableCreateExternalTable',
            'EnableEventHubSource',
        ],
        Dashboards: [
            [
                'HideDashboardsOnly',
                'Block access to dashboards (removes the dashboards icon from the left navigation bar and all dashboards features from KWE, like pin-to-dashboards)',
            ],
            'EnableDashboardsDevMenu',
            'DashboardsControlCommandParameters',
            'EnableQueryParameterLogging',
        ],
        KWE: [
            [
                'TenantAdminSettings',
                'When enabled, the user interface that allows a tenant admin to enable and disable features will be shown',
            ],
            'ShowItalyAccessibilityDeclarations',
            'WhatIsNew',
            'DisableTelemetryPiiRedactor',
            'EnableRoamingProfile',
            'UseMeControl',
            'AddSuggestNewFeatureLink',
            'EnableNoLoginVirtualCluster',
            'DisableFeedback',
            'EnableNPS',
            'ShowNavigation',
            'UseLocalStorageForTokens',
            'enableLanguageHotReloading',
            'HomepagePromotionBanner',
            'ppe',
            'canary',
            ['EnableExploreData', 'Enable the Explore Data menu item in the tile menu'],
            ['enableSelectedColumnInfo', 'Enable the Explore Data selected column info section'],
            [
                'enableAuthMetadataScopes',
                'Enable fetching the clusters scopes for obtaining token from the auth/metadata api',
            ],
        ],
        Visuals: ['SecurePlotlyIframe'],
        'Data management': ['BrowseSource'],
        ['One click']: ['enableTablePolicy'],
        ['Virtual cluster']: ['VirtualClusterFastProvisioning'],
    },
    baseLegacyFeatureFlags,
    dashboardsUrlFeatureFlags
);

export const featureFlags = mergeFeatureFlagLists(urlFeatureFlags, kweFeatureFlags, dashboardFlags, visualFwkFlags);

export type FeatureFlagMap = FeatureFlagsSetting<FeatureFlag>;
export type FeatureFlagMapAll = FeatureFlags<FeatureFlag>;

const StickyFeatureFlags: (keyof FeatureFlagMap)[] = [
    'enableTablePolicy',
    'ppe',
    'canary',
    'HomepagePromotionBanner',
    'ConnectionPaneUsingFluentV9',
];

const deletedFeatureFlags: DeletedFlags<FeatureFlag> = {
    VirtualCluster: { date: '1969-12-31' },
    Homepage: { date: '1969-12-31' },
    VirtualClusterUpgrade: { date: '1969-12-31' },
    IframeSaving: { date: '2024-05-29' },
    HighchartsSupport: { date: '2024-05-29' },
    'parameter--auto-reset': { date: '2024-06-05' },
    'parameter--all-sends-null': { date: '2024-06-05' },
    'parameter--no-selection': { date: '2024-06-05' },
    'parameter--default-query-result': { date: '2024-06-05' },
    BrowserTimeZoneSetting: { date: '2024-06-05' },
    '1sAutoRefresh': { date: '2024-06-06' },
};

const featureFlagsKeyName = 'kwe.feature.flag.overrides';

function mergeForcedFlags(queryParamFlags: FeatureFlagMap, tenant: undefined | FeatureFlagsSetting<FeatureFlag>) {
    return mergeFlagsConfig<FeatureFlag>({
        queryParam: queryParamFlags,
        domain: KWE_CONSTANTS.domainFeatureFlags[window.location.host],
        tenant,
        forced: KWE_CONSTANTS.forcedFeatureFlags[window.location.host],
    });
}

export interface InitAppFlagsArgs {
    readonly isIbizaIFrame: boolean;
    readonly isIFrame: boolean;
    readonly queryParamFlags: FeatureFlagMap;
}

export interface InitAppFlagsReturn {
    readonly service: FeatureFlagsService<FeatureFlag>;
    setTenantFlags(flags: FeatureFlagsSetting<FeatureFlag>): void;
}

export function initFeatureFlagsService({
    isIbizaIFrame,
    isIFrame,
    queryParamFlags,
}: InitAppFlagsArgs): InitAppFlagsReturn {
    let featureFlags = kweSiteFeatureFlags;
    if (isIbizaIFrame) {
        featureFlags = ibizaFeatureFlags;
    } else if (isIFrame) {
        featureFlags = iFrameFeatureFlags;
    }

    // Keys are typed so we can ensure values added later are in the right order

    const mutable = mergeFlagsConfig<FeatureFlag>({
        environment: featureFlags,
        build: KWE_ENV.featureFlags,
    });

    let forced = mergeForcedFlags(queryParamFlags, undefined);

    const service = new FeatureFlagsService<FeatureFlag>(
        (f) => {
            const _forced = forced.get(f);
            if (_forced) {
                return { ..._forced, force: true };
            }

            return mutable.get(f)!;
        },
        deletedFeatureFlags,
        featureFlagsKeyName
    );

    if (!isIbizaIFrame) {
        mobx.runInAction(() => {
            for (const flag of StickyFeatureFlags) {
                if (flag in queryParamFlags) {
                    service.setFlagOverride(flag, queryParamFlags[flag]);
                }
            }
        });
    } else {
        // Remove stick flags from ibiza. This branch exists due to a ibiza
        // accidentally supporting sticky flags. Can be removed after a few weeks.
        for (const flag of StickyFeatureFlags) {
            if (flag in queryParamFlags) {
                service.setFlagOverride(flag, undefined);
            }
        }
    }

    const setTenantFlags = (tenant: FeatureFlagsSetting<FeatureFlag>) => {
        forced = mergeForcedFlags(queryParamFlags, tenant);
    };

    return { service, setTenantFlags };
}
