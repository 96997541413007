import { CSSProperties } from 'react';
import kebabCase from 'lodash/kebabCase';

export const clipboardStyle: CSSProperties = {
    fontFamily: `Menlo, Monaco, 'Courier New', monospace, Calibri`,
    fontWeight: 'normal',
    fontSize: 12,
};

export const clipboardStyleAsString = Object.entries(clipboardStyle)
    .map(([key, value]) => `${kebabCase(key)}: ${value};`)
    .join(' ');
