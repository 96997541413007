import { KweException } from '../utils';
import { Field, type DataFrame, type UField } from './DataFrame';
import type { DataFrameTypes, KustoDataType } from './dataTypes';

type DataFrameType<T extends KustoDataType> = DataFrameTypes[T] extends string ? string : DataFrameTypes[T] | null;
export type KustoDataTypeFunc = <ItemKustoDataType extends KustoDataType>(
    name: string,
    type: ItemKustoDataType
) => DataFrameType<ItemKustoDataType>;
/**
 * Small helper to see if your Field matches against some target types you pass in
 *
 * @example
 *
 * isOneOfType(new Field('name', 'int', [...values...]), KUSTO_NUMERIC_TYPES); // true
 * isOneOfType(new Field('name', 'datetime', [...values...]), KUSTO_TIME_TYPES); // true
 * isOneOfType(new Field('name', 'string', [...values...]), KUSTO_SCALAR_TYPES); // true
 * isOneOfType(new Field('name', 'dynamic', [...values...]), KUSTO_DATA_TYPES); // true
 */
export function isOneOfType<TKustoType extends KustoDataType>(
    field: UField,
    typesToMatchAgainst: ReadonlyArray<TKustoType>
): field is Extract<UField, { type: ReadonlyArray<TKustoType>[number] }> {
    return typesToMatchAgainst.some((matchingType) => matchingType === field.type);
}

export function getFrameField<T extends KustoDataType>(
    fields: readonly UField[],
    fieldName: string,
    type: T,
    index: number
): DataFrameType<T> {
    const field = fields.find((field) => field.name === fieldName);

    if (field === undefined) {
        throw new KweException(`Field ${fieldName} and type ${type} is undefined`);
    }

    if (field.type !== type) {
        throw new KweException(`Field ${fieldName} is not of type ${type}`);
    }

    const value = field.values.at(index);

    if (value === undefined) {
        throw new KweException(`Field ${fieldName} of type ${type} value is undefined`);
    }

    return value as DataFrameType<T>;
}

export function getFrameObjects<TResult>(
    frame: DataFrame,
    itemMapper: (kustoDataTypeFunc: KustoDataTypeFunc) => TResult
): TResult[] {
    return new Array(frame.size).fill(undefined).map((_, index) => {
        return itemMapper((name, type) => {
            return getFrameField(frame.fields, name, type, index);
        });
    });
}

export function truncateDataFrame(dataFrame: DataFrame, size: number): DataFrame {
    if (dataFrame.size <= size) {
        return dataFrame;
    }

    return {
        fields: dataFrame.fields.map(
            // No way to make this type safe without making the code much longer (ie. adding more JavaScript)
            <T extends KustoDataType, V extends DataFrameTypes[KustoDataType]>(field: Field<T, V>) =>
                // Cast required because typescript broadens the `UField` union
                new Field<T, V>(field.name, field.type, field.values.slice(0, size)) as UField
        ),
        size,
    };
}
