import React, { useCallback } from 'react';
import { Input, InputProps } from '@fluentui/react-components';
import { Path } from 'react-hook-form';

import { RHFField, RHFFieldProps, TFormFieldsBase } from './RHFField';

export interface RHFInputProps<TFormFields extends TFormFieldsBase, TFieldName extends Path<TFormFields>>
    extends Omit<RHFFieldProps<TFormFields, TFieldName>, 'Component'> {
    /** Props of the `<Input />` */
    inputProps?: InputProps;
}

/**
 * Fluent `<Input />` nested in `<Field />` wrapped inside React-Hook-Form `<Controller />`.
 * Used to support validation and error messages using the RHF library.
 */
export const RHFInput = <TFormFields extends TFormFieldsBase, TFieldName extends Path<TFormFields>>({
    controllerProps,
    fieldProps,
    inputProps,
}: RHFInputProps<TFormFields, TFieldName>) => {
    const inputComponent = useCallback<RHFFieldProps<TFormFields, TFieldName>['Component']>(
        ({ field: rhfField }) => <Input {...rhfField} {...inputProps} />,
        [inputProps]
    );

    return <RHFField controllerProps={controllerProps} fieldProps={fieldProps} Component={inputComponent} />;
};
