import {
    AccountInfo,
    AuthenticationResult,
    InteractionRequiredAuthError,
    IPublicClientApplication,
    PopupRequest,
    PublicClientApplication,
    RedirectRequest,
} from '@azure/msal-browser';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { getClusterScopesFromAuthMetadata, KustoDomains } from '@kusto/client';
import { getTelemetryClient } from '@kusto/query';
import { Account, castToError, Err, err, IKweTelemetry, KweException, ok, Result } from '@kusto/utils';

import { KWE_ENV } from '../../common/constants';
import { IAuthProvider, Prompt } from '../IAuthProvider';
import { parseAuthState, stringifyAuthState } from './AuthState';
import { interactionInProgressWorkaround } from './interactionInProgressWorkaround';
import { MsalPublicClientApplicationConfig } from './MsalPublicClientApplicationConfig';
import { createAuthorityUrl, isHomeTenant, isMSAAccount, MSA_PASSTHROUGH_TENANT } from './MsalUtils';

/**
 * Turns an unknown value into an Err<Error> type. Useful to turn an exception
 * into Err<Error> object.
 * @example
 * try {
 *   return ok(<valid value>)
 * } catch (e: unknown) {
 *   return asErr(e);
 * }
 *
 * @deprecated
 * If you're handling a thrown error, log it if it was an exception, and return
 * something that doesn't include a stack trace.
 */
export function asErr(value: unknown): Err<Error> {
    return err(castToError(value));
}

const { trackTrace, trackException } = getTelemetryClient({
    component: 'MsalAuthenticationProvider',
    flow: '',
});

/**
 * Implementation of IAuthProvider above @azure/msal-browser
 */
export class MsalAuthenticationProvider implements IAuthProvider {
    private defaultScopes: string[];
    private defaultAuthority: string;
    private application: IPublicClientApplication;
    private allowRedirect: boolean;
    private enableAuthMetadataScopes: boolean;
    private telemetry: IKweTelemetry;
    private loggedIn?: (authenticationResult: AuthenticationResult) => void;
    private loggingOut?: () => void;

    private constructor(
        application: IPublicClientApplication,
        kustoDomains: KustoDomains,
        enableAuthMetadataScopes: boolean,
        telemetry: IKweTelemetry,
        allowRedirect = true
    ) {
        this.defaultScopes = [`https://help${kustoDomains.Default}/.default`];
        this.defaultAuthority = createAuthorityUrl();
        this.allowRedirect = allowRedirect;
        this.application = application;
        this.enableAuthMetadataScopes = enableAuthMetadataScopes;
        this.telemetry = telemetry;
    }

    public static async createPublicClientApplication({
        kustoDomains,
        redirectUri,
        clientId,
        useLocalStorageForTokens,
        enableAuthMetadataScopes,
        telemetry,
        allowRedirect = true,
    }: {
        kustoDomains: KustoDomains;
        redirectUri: string;
        clientId: string;
        useLocalStorageForTokens: boolean;
        enableAuthMetadataScopes: boolean;
        telemetry: IKweTelemetry;
        allowRedirect?: boolean;
    }) {
        const config = MsalPublicClientApplicationConfig(redirectUri, clientId, useLocalStorageForTokens);
        const application = await PublicClientApplication.createPublicClientApplication(config);
        return new MsalAuthenticationProvider(
            application,
            kustoDomains,
            enableAuthMetadataScopes,
            telemetry,
            allowRedirect
        );
    }

    async getClusterScopes(clusterUrl: string): Promise<string[] | undefined> {
        if (!this.enableAuthMetadataScopes) {
            return undefined;
        }
        const scopes = await getClusterScopesFromAuthMetadata({
            clusterUrl,
            telemetry: this.telemetry,
            defaultScope: this.defaultScopes,
        });
        return scopes;
    }

    /**
     * Silently acquire an access token for a given set of scopes. If token can't be fetched silently, redirect to AAD login page.
     * Redirection will be skipped if allowRedirect=false (=user is signedOut) or if the token is not asked for the active account (the account that is currently logged in)
     */
    async getToken(scopes?: string[], account?: Account): Promise<Result<string, Error>> {
        const res = await this.getTokenSilently(account, scopes);
        if (res.err instanceof InteractionRequiredAuthError) {
            trackTrace('getToken: getTokenSilently failed with InteractionRequiredAuthError', SeverityLevel.Verbose, {
                activeHomeAccountId: this.getActiveAccount()?.homeAccountId,
                requestedHomeAccountId: account?.homeAccountId,
                allowRedirect: this.allowRedirect,
            });

            if (!this.allowRedirect || (account && !this.isActiveAccount(account))) {
                return res;
            }

            const curAccount = account ?? this.getActiveAccount();
            const authority = this.getAuthority(curAccount);
            return await this.acquireTokenRedirect({
                authority,
                account: account as AccountInfo | undefined,
                scopes: scopes ?? this.defaultScopes,
            });
        }

        return res;
    }

    /**
     * Parses the token from an MSAL's login redirect.
     */
    async handleRedirectPromise(): Promise<void> {
        const loginResult = await this.application.handleRedirectPromise();
        interactionInProgressWorkaround();

        if (loginResult?.account) {
            trackTrace('handleRedirectPromise returned a value', SeverityLevel.Verbose);
            this.application.setActiveAccount(loginResult.account);
            if (this.loggedIn) {
                await this.loggedIn(loginResult);
            }
        }

        if (loginResult?.state) {
            const authState = parseAuthState(loginResult.state);
            if (authState.basePathName && authState.basePathName !== KWE_ENV.publicUrl) {
                const redirectTo = `${window.location.protocol}//${window.location.host}${authState.basePathName}`;
                trackTrace(`handleRedirectPromise: basePathName exist in state`, SeverityLevel.Information, {
                    redirectTo,
                });
                window.location.replace(redirectTo);
            }
        }
    }

    async getTokenSilently(account?: Account, scope?: string[]): Promise<Result<string, Error>> {
        trackTrace('getTokenSilently: started', SeverityLevel.Verbose);
        try {
            const curAccount = account ?? this.application.getActiveAccount();
            if (!curAccount) {
                trackTrace(
                    'getTokenSilently: no account passed and there is no active account.',
                    SeverityLevel.Information
                );
                return err(
                    new InteractionRequiredAuthError(
                        'login_required',
                        'No account passed and there is no active account.'
                    )
                );
            }

            trackTrace('getTokenSilently: calling acquireTokenSilent', SeverityLevel.Verbose);
            const authority = this.getAuthority(curAccount);
            const res = await this.application.acquireTokenSilent({
                authority,
                scopes: scope ?? this.defaultScopes,
                account: curAccount as AccountInfo,
                redirectUri: this.getRedirectUri(),
            });

            trackTrace('getTokenSilently: acquireTokenSilent returned a value', SeverityLevel.Verbose);
            return ok(res.accessToken);
        } catch (ex: unknown) {
            const err = asErr(ex);
            trackException(err.err, 'getTokenSilently: error');
            return err;
        }
    }

    getAccount(localAccountId?: string): Account | undefined {
        trackTrace('getAccount', SeverityLevel.Verbose, { localAccountId: `${localAccountId}` });
        if (localAccountId) {
            return this.application.getAccountByLocalId(localAccountId) ?? undefined;
        }

        return this.application.getActiveAccount() ?? undefined;
    }

    getAccountSafe(localAccountId?: string): Account | undefined {
        try {
            return this.getAccount(localAccountId);
        } catch (e) {
            trackException(new KweException('IFrameAuthenticationProvider - getAccountSafe'));
        }
    }

    getAllAccounts(): Account[] {
        return this.application.getAllAccounts();
    }

    getActiveAccount(): Account | undefined {
        return this.application.getActiveAccount() ?? undefined;
    }

    getAccountByHomeIdAndTenant(homeAccountId: string, tenant: string): Account | undefined {
        const allAccounts = this.application.getAllAccounts();
        return allAccounts.find((account) => account.tenantId === tenant && account.homeAccountId === homeAccountId);
    }

    getAccountByUsernameAndTenant(tenant: string, username: string | undefined): Account | undefined {
        const allAccounts = this.application.getAllAccounts();
        if (username) {
            return allAccounts.find((account) => account.tenantId === tenant && account.username === username);
        } else {
            const activeAccount = this.getAccount();
            if (!activeAccount) {
                trackTrace('getAccountByUsernameAndTenant: username is empty and there is no active account');
                return undefined;
            }
            return allAccounts.find(
                (account) => account.tenantId === tenant && activeAccount.homeAccountId === account.homeAccountId
            );
        }
    }

    /**
     * Gets home account by username. If username is empty, get the home account of the logged in user.
     */
    getHomeAccountByUsername(username?: string): Account | undefined {
        const allAccounts = this.application.getAllAccounts();
        if (username) {
            return allAccounts.find((account) => account.username === username && isHomeTenant(account));
        } else {
            const activeAccount = this.getAccount();
            if (activeAccount) {
                return allAccounts.find(
                    (account) => account.homeAccountId === activeAccount.homeAccountId && isHomeTenant(account)
                );
            }
            return undefined;
        }
    }

    async loginRedirect(
        loginHint?: string | undefined | null,
        tenant?: string,
        prompt?: Prompt,
        scopes?: string[]
    ): Promise<void> {
        this.allowRedirect = true;
        await this.login(loginHint, tenant, prompt, scopes);
    }

    async logout(): Promise<void> {
        trackTrace('logout: called', SeverityLevel.Information);
        if (this.loggingOut) {
            this.loggingOut();
        }
        await this.application.logoutRedirect({
            authority: this.getAuthority(this.getAccount()),
        });
    }

    async login(
        loginHint?: string | undefined | null,
        tenant?: string,
        prompt?: Prompt,
        scopes?: string[]
    ): Promise<boolean> {
        if (!this.allowRedirect) {
            trackTrace('login: redirect skipped allowRedirect is false', SeverityLevel.Information);
            return false;
        }

        const loginCommonProps = this.loginCommonProps(loginHint, tenant, prompt, scopes);
        trackTrace('login: called', SeverityLevel.Information, {
            authority: loginCommonProps.authority ?? 'unknown',
            loginHint: `${loginHint}`,
            prompt: `${prompt}`,
            disablePiiRedactor: false,
        });
        await this.application.loginRedirect(loginCommonProps);
        return true;
    }

    async popupLogin(
        loginHint?: string | undefined | null,
        tenant?: string,
        prompt?: Prompt,
        scopes?: string[],
        popupWindowAttributes?: PopupRequest['popupWindowAttributes']
    ): Promise<Result<Account | undefined, Error>> {
        try {
            const loginCommonProps = this.loginCommonProps(loginHint, tenant, prompt, scopes);
            trackTrace('popupLogin: called', SeverityLevel.Information, {
                authority: loginCommonProps.authority ?? 'unknown',
                loginHint: `${loginHint}`,
                prompt: `${prompt}`,
                disablePiiRedactor: false,
            });
            const res = await this.application.loginPopup({
                ...loginCommonProps,
                popupWindowAttributes,
                redirectUri: this.getRedirectUri(),
            });
            trackTrace('popupLogin: result', SeverityLevel.Information, {
                withAccount: res.account ? 'true' : 'false',
            });
            return ok(res.account ?? undefined);
        } catch (ex: unknown) {
            const err = asErr(ex);
            trackException(err.err, 'popupLogin: error');
            return err;
        }
    }

    private loginCommonProps(
        loginHint?: string | undefined | null,
        tenant?: string,
        prompt?: Prompt,
        scopes?: string[]
    ): PopupRequest | RedirectRequest {
        const authority = createAuthorityUrl(tenant);
        return {
            authority: authority,
            scopes: scopes ?? this.defaultScopes,
            loginHint: loginHint ?? undefined,
            state: stringifyAuthState({ basePathName: KWE_ENV.publicUrl }),
            prompt: prompt,
        };
    }

    private async acquireTokenRedirect(request: RedirectRequest): Promise<Result<string, Error>> {
        try {
            await this.application.acquireTokenRedirect(request);
            trackTrace(
                'acquireTokenRedirect: should never happen. acquireTokenRedirect should redirect',
                SeverityLevel.Warning
            );
            return ok('');
        } catch (e: unknown) {
            return asErr(e);
        }
    }

    private getAuthority(account?: Account): string {
        if (!account) {
            return this.defaultAuthority;
        }
        return isHomeTenant(account)
            ? isMSAAccount(account)
                ? createAuthorityUrl(MSA_PASSTHROUGH_TENANT)
                : this.defaultAuthority
            : createAuthorityUrl(account?.tenantId);
    }

    /**
     * Checks if the given account is the active one. In case of undefined, returns true.
     */
    private isActiveAccount(account: Account): boolean {
        const activeAccount = this.getActiveAccount();
        return account.homeAccountId === activeAccount?.homeAccountId;
    }

    /**
     * blank.html is recommended here: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
     */
    private getRedirectUri(): string {
        return `${window.location.protocol}//${window.location.host}/blank.html`;
    }

    registerEvents(onLogin: (authenticationResult: AuthenticationResult) => void, onLoggingOut: () => void) {
        this.loggedIn = onLogin;
        this.loggingOut = onLoggingOut;
    }

    setActiveAccount(account: Account) {
        this.application.setActiveAccount(account as AccountInfo);

        // after initial account is verified, login redirect should be enabled
        this.allowRedirect = true;
    }
}
