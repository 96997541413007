import React from 'react';
import { Tooltip, TooltipProps } from '@fluentui/react-components';

export interface TooltipIfNeededProps {
    tooltip?: TooltipProps['content'];
    children: React.ReactElement;
}

// Fluent 9 tooltip wrapper
export const TooltipIfNeeded: React.FC<TooltipIfNeededProps> = ({ children, tooltip }) => {
    if (tooltip === null || tooltip === undefined || tooltip === '') {
        return children;
    }

    return (
        <Tooltip content={tooltip} relationship="description" positioning="above-start">
            {children}
        </Tooltip>
    );
};
