.results {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .tabsBar {
        display: flex;
        padding: 0 var(--spacingVerticalM);

        .tabsList {
            flex: 1;
        }

        .resultsCount {
            align-self: center;
            padding: 0 var(--spacingHorizontalM);
        }
    }

    .resultsContact {
        flex: 1;
        padding: var(--spacingVerticalS) var(--spacingHorizontalXXL) var(--spacingVerticalL);
        overflow: hidden;
    }
}
