import { getKustoWorker, type KustoWorker } from '@kusto/monaco-kusto';
import type { ClusterReference, Database, DatabaseReference } from '@kusto/monaco-kusto';
import { editor, Position } from 'monaco-editor/esm/vs/editor/editor.api';

import { KustoClientLocale, KustoDomains, parseClusterConnectionString } from '@kusto/client';
import type { IKustoClient } from '@kusto/client';
import type { IKweTelemetry } from '@kusto/utils';

import { fetchDatabaseSchema, fetchDatabaseSchemaAsEntities, fetchDatabasesNames } from './schema';
import { SchemaFetcher } from './SchemaFetcher';
import { convertDatabaseJsonToDatabaseSymbol } from './utils';

export async function initWorkerFromModel(model: editor.ITextModel) {
    const workerAccessor = await getKustoWorker();
    return workerAccessor ? workerAccessor(model.uri) : undefined;
}

export async function initWorker(editor: editor.IStandaloneCodeEditor) {
    const model = editor.getModel();
    if (!model) {
        return;
    }
    return await initWorkerFromModel(model);
}

/**
 * Load cluster or database symbols when using cross cluster queries like `cluster('<cluster>').database('<database>')`.
 */
export async function loadSchemaForCrossClusterQueries(
    kustoClient: IKustoClient,
    editor: editor.IStandaloneCodeEditor,
    telemetry: IKweTelemetry,
    kustoDomains: KustoDomains,
    settings: {
        liteSchemaExplorationEnabled: boolean; // prefer the '.show entities' for schema exploration (it's liter)
    },
    t: KustoClientLocale,
    abortSignal: AbortSignal
) {
    telemetry = telemetry.bind({ flow: 'loadSchemaForCrossClusterQueries' });

    const model = editor.getModel();
    if (!model) {
        telemetry.trace('model is empty');
        return;
    }

    telemetry.trace('clusterReferences: start');
    const offset = model.getOffsetAt(editor.getPosition() ?? new Position(0, 0));
    const worker = await initWorker(editor);
    const modelUri = model.uri.toString();

    if (abortSignal.aborted || !worker) {
        return;
    }

    loadSchemaForClusters(kustoClient, telemetry, t, kustoDomains, offset, worker, modelUri, abortSignal);
    loadSchemaForDatabases(kustoClient, telemetry, t, kustoDomains, settings, offset, worker, modelUri, abortSignal);
}

async function loadSchemaForClusters(
    kustoClient: IKustoClient,
    telemetry: IKweTelemetry,
    t: KustoClientLocale,
    kustoDomains: KustoDomains,
    offset: number,
    worker: KustoWorker,
    modelUri: string,
    abortSignal: AbortSignal
) {
    telemetry.startTrackEvent('clusterReferences: getClusterReferences: duration');
    const clusterReferences: ClusterReference[] = (await worker.getClusterReferences(modelUri, offset)) ?? [];
    telemetry.stopTrackEvent('clusterReferences: getClusterReferences: duration');
    if (abortSignal.aborted) {
        return;
    }

    telemetry.trace('clusterReferences: cluster references count: ' + clusterReferences.length);
    const promises: Promise<void>[] = clusterReferences.map((clusterReference: ClusterReference) => {
        return fetchDatabasesNames(
            kustoClient,
            getConnectionString(telemetry, kustoDomains, clusterReference.clusterName),
            t,
            abortSignal
        ).then((databasesResult) => {
            // TODO: consider adding error indication in the auto completion result to user
            if (databasesResult.kind !== 'ok') {
                return;
            }
            telemetry.trace('clusterReferences: fetchDatabasesNames: databases count: ' + databasesResult.value.length);
            telemetry.startTrackEvent('clusterReferences: addClusterToSchema: duration');
            worker.addClusterToSchema(modelUri, clusterReference.clusterName, databasesResult.value);
            telemetry.stopTrackEvent('clusterReferences: addClusterToSchema: duration');
        });
    });

    try {
        await Promise.all(promises);
    } catch (e) {
        if ((e as Error).message === 'Network Error') {
            return;
        }
        telemetry.exception(`clusterReferences: failed to load schema for cross cluster queries`, { exception: e });
    }
}

async function loadSchemaForDatabases(
    kustoClient: IKustoClient,
    telemetry: IKweTelemetry,
    t: KustoClientLocale,
    kustoDomains: KustoDomains,
    settings: { liteSchemaExplorationEnabled: boolean },
    offset: number,
    worker: KustoWorker,
    modelUri: string,
    abortSignal: AbortSignal
) {
    telemetry.trace('databaseReferences: start');

    telemetry.startTrackEvent('databaseReferences: getDatabaseReferences: duration');
    const databaseReferences: DatabaseReference[] = (await worker.getDatabaseReferences(modelUri, offset)) ?? [];
    telemetry.stopTrackEvent('databaseReferences: getDatabaseReferences: duration');

    telemetry.trace(`databaseReferences: getDatabaseReferences: databases count: ${databaseReferences.length}`);
    const promises: Promise<void>[] = databaseReferences.map((reference: DatabaseReference) => {
        telemetry.startTrackEvent('databaseReferences: fetchDatabaseSchema: duration');
        const clusterUrl = getConnectionString(telemetry, kustoDomains, reference.clusterName);
        const databaseName = reference.databaseName;
        const schemaFetcher = new SchemaFetcher(
            kustoDomains,
            clusterUrl,
            settings.liteSchemaExplorationEnabled,
            () => {
                return fetchDatabaseSchemaAsEntities(
                    kustoClient,
                    t,
                    clusterUrl,
                    databaseName,
                    telemetry,
                    undefined,
                    abortSignal
                );
            },
            () => {
                return fetchDatabaseSchema(kustoClient, t, clusterUrl, databaseName, undefined, abortSignal);
            },
            telemetry
        );

        return schemaFetcher.fetchSchema().then((databaseResult) => {
            telemetry.stopTrackEvent('databaseReferences: fetchDatabaseSchema: duration');

            // TODO: consider adding error indication in the auto completion result to user
            if (databaseResult.kind !== 'ok') {
                return;
            }

            const kustoMonacoDatabaseSchema: Database = convertDatabaseJsonToDatabaseSymbol(databaseResult.value);
            telemetry.startTrackEvent('databaseReferences: addDatabaseToSchema: duration');
            worker.addDatabaseToSchema(modelUri, reference.clusterName, kustoMonacoDatabaseSchema);
            telemetry.stopTrackEvent('databaseReferences: addDatabaseToSchema: duration');
        });
    });

    try {
        await Promise.all(promises);
    } catch (e) {
        if ((e as Error).message === 'Network Error') {
            return;
        }
        telemetry.exception(`clusterReferences: failed to load schema for cross cluster queries`, { exception: e });
    }
}

function getConnectionString(telemetry: IKweTelemetry, kustoDomains: KustoDomains, clusterName: string) {
    const res = parseClusterConnectionString(telemetry, kustoDomains, clusterName);
    if (res.kind !== 'ok') {
        throw new Error(res.err.code);
    }
    return res.value.connectionString.replace('/;fed=true', '').toString();
}
