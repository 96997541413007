import { Account, castToError, err, IExceptionTelemetry, IKweTelemetry, KweException, ok, Result } from '@kusto/utils';

import { postGetToken } from '../../utils/IFrame/IFrameCommunication';
import { TokenMessage } from '../../utils/IFrame/IFrameMessage';
import { IAuthProvider } from '../IAuthProvider';
import { decodeJwtToken, Profile } from './IFrameUtils';

const waitingForTokenTimeout = 10000; // 10 seconds;

/**
 * Makes a promise fails if not resolve in 'ms' milliseconds
 */
const promiseTimeout = (ms: number, promise: Promise<string>): Promise<Result<string, Error>> => {
    // Create a promise that rejects in <ms> milliseconds
    const timeoutPromise = new Promise<string>((resolve, reject) => {
        const id = setTimeout(() => {
            clearTimeout(id);
            reject('Timed out');
        }, ms);
    });

    // Returns a race between our timeout and the passed in promise
    return Promise.race([promise, timeoutPromise])
        .then((res) => ok(res))
        .catch((ex) => err(castToError(ex)));
};

const DEFAULT_SCOPE = 'query';

/**
 * Provide authentication tokens through posting messages to iframe parent.
 */
export class IFrameAuthenticationProvider implements IAuthProvider {
    // A promise is resolved when a token is received in handleIncomingMessage
    private resolveTokenPromises: Partial<Record<string, ((value: string | PromiseLike<string>) => void) | undefined>> =
        {};

    // stores the latest version of an access token for kusto.
    private currentTokens: Partial<Record<string, string>> = {};
    private currentProfile: Profile | undefined = undefined;

    // a promise representing the wait for getting the token for the 1st time.
    // further getToken calls will return immediately with the latest version of the token we have)
    private tokenPromisesWithTimeout: Partial<Record<string, Promise<Result<string, Error>>>> = {};

    private telemetryClient: IKweTelemetry;

    constructor(telemetryClient: IKweTelemetry) {
        this.telemetryClient = telemetryClient.bind({ component: 'IFrameAuthenticationProvider' });
        this.postGetTokenWithTelemetry('query');
        window.addEventListener('message', (event) => this.handleIncomingMessage(event), false);
        this.setTokenPromiseWithTimeout();
    }

    setTokenPromiseWithTimeout(scope: string = DEFAULT_SCOPE) {
        this.tokenPromisesWithTimeout[scope] = promiseTimeout(
            waitingForTokenTimeout,
            new Promise<string>((resolve) => {
                this.resolveTokenPromises[scope] = resolve;
            })
        );
    }

    waitingForToken(scope: string = DEFAULT_SCOPE): Promise<Result<string, Error>> {
        return this.tokenPromisesWithTimeout[scope]!.then((result) => {
            this.telemetryClient.trace('waitingForToken', { status: result.kind, result: result.err });
            return result;
        }).catch((ex: unknown) => {
            this.telemetryClient.trace('waitingForToken exception', { message: castToError(ex).message });
            throw ex;
        });
    }

    refreshToken(scope: string = DEFAULT_SCOPE, _: Account): Promise<string> {
        this.telemetryClient.trace('Refreshing Token');
        this.resolveTokenPromises[scope] = undefined;
        this.setTokenPromiseWithTimeout(scope);
        this.postGetTokenWithTelemetry(scope);
        return this.waitingForToken(scope)
            .then((res) => res.value ?? '')
            .catch((ex) => castToError(ex).message);
    }

    getClusterScopes(_clusterUrl: string): Promise<string[] | undefined> {
        return Promise.resolve(undefined);
    }

    getToken(scopes: string[] = [DEFAULT_SCOPE], account?: Account): Promise<Result<string, Error>> {
        if (account) {
            this.telemetryClient.trace("getToken: an account was passed although it's not supported in iFrame", {
                activeHomeAccountId: this.getAccount()?.homeAccountId,
                requestedHomeAccountId: account?.homeAccountId,
            });
            return Promise.resolve(
                err(new Error('Method getToken with a defined account is not supported in iFrame Auth Provider.'))
            );
        }

        const scope = scopes[0];
        const token = this.currentTokens[scope];
        if (!token) {
            if (!this.tokenPromisesWithTimeout[scope]) {
                this.setTokenPromiseWithTimeout(scope);
            }
            this.postGetTokenWithTelemetry(scope);
            return this.waitingForToken(scope);
        }
        return Promise.resolve(ok(token));
    }

    getTokenSilently(_account?: Account, scopes: string[] = [DEFAULT_SCOPE]): Promise<Result<string, Error>> {
        const token = this.currentTokens[scopes[0]];
        if (token) {
            return Promise.resolve(ok(token));
        } else {
            return Promise.resolve(err(new Error('currentToken is undefined')));
        }
    }

    getAccount(): Account | undefined {
        const profile = this.currentProfile;
        if (!profile) {
            return undefined;
        }

        return {
            username: profile.upn ?? profile.username ?? profile.email,
            homeAccountId: profile.oid,
            localAccountId: profile.oid,
            tenantId: profile.tid,
            name: profile.name,
        } as Account;
    }

    getAccountSafe(): Account | undefined {
        try {
            return this.getAccount();
        } catch (e) {
            const err = new KweException('MsalAuthenticationProvider - getAccountSafe');
            this.telemetryClient.exception('getAccountSafe', err as unknown as IExceptionTelemetry);
        }
    }

    getAccountByHomeIdAndTenant(): Account | undefined {
        return this.getAccount();
    }

    getAccountByUsernameAndTenant(): Account | undefined {
        return this.getAccount();
    }

    changeAccountSilently(): Promise<boolean> {
        throw new Error('Method changeAccountSilently is not supported in iFrames.');
    }

    logout(): Promise<void> {
        throw new Error('Method logout is not supported in iFrames.');
    }

    login(): Promise<boolean> {
        throw new Error('Method login is not supported in iFrames.');
    }

    private postGetTokenWithTelemetry(scope: string) {
        this.telemetryClient.trace('post get token request', {
            scope,
        });
        postGetToken(scope);
    }

    private handleIncomingMessage(event: MessageEvent) {
        const eventData: TokenMessage = event.data;
        switch (eventData.type) {
            case 'postToken':
                const scope = eventData.scope ?? DEFAULT_SCOPE;
                let token = eventData.message;

                // If this is the first time we got a token, resolve the promise.
                const tokenPromise = this.resolveTokenPromises[scope];

                this.telemetryClient.trace('handling postToken message', {
                    scope,
                    promiseToResolve: tokenPromise === undefined ? 'none' : 'exist',
                });

                // if we got the Bearer part, strip it out. We just want the token.
                if (token.startsWith('Bearer ')) {
                    token = token.substring('Bearer '.length);
                }

                // Update our token to the newest posted token.
                this.currentTokens[scope] = token;
                this.currentProfile = decodeJwtToken(token);

                if (tokenPromise) {
                    const resolve = tokenPromise;
                    this.resolveTokenPromises[scope] = undefined;
                    resolve(token);
                }

                break;
            default:
                break;
        }
    }
}
