.schemaColumnsEmpty {
    height: 100%;
    display: flex;
    justify-content: center;
}

.schemaColumns {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    .columnList {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .columnInfo {
        max-height: 40%;
        flex-shrink: 0;
        box-sizing: border-box;
        background: var(--colorNeutralBackground1);
        border-radius: var(--borderRadiusXLarge);
        margin: var(--spacingVerticalL) var(--spacingHorizontalL);
        padding: var(--spacingVerticalL) var(--spacingHorizontalL);
        overflow-x: auto;

        .selectedColumn {
            display: flex;
            gap: var(--spacingHorizontalXS);
            align-items: center;
        }
    }
}
