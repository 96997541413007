import React from 'react';
import { Dropdown, DropdownProps, Field, Option } from '@fluentui/react-components';

import { DataTableColumn } from '@kusto/client';

import { useQueryColumns } from '../../../../hooks/useResults';
import { useStrings } from '../../../../hooks/useStrings';
import { FieldLabel } from './FieldLabel';

import styles from '../Popovers.module.scss';

export interface ColumnDropdownProps {
    /** defaults to 'Column' */
    label?: string;
    selectedColumn?: DataTableColumn;
    onOptionSelect: (selectedColumn: DataTableColumn) => void;
    disabled?: boolean;
    /** Whether it's a new pill or editing an existing pill. The columns list would be different. */
    isEditMode: boolean;
}

export const ColumnDropdown: React.FunctionComponent<ColumnDropdownProps> = ({
    label,
    selectedColumn,
    onOptionSelect,
    disabled,
    isEditMode,
}) => {
    const { strings } = useStrings();

    const dropLastPill = isEditMode; // when editing, we need to remove the last pill to get the correct columns
    const computedResults = useQueryColumns(dropLastPill);
    const fields = computedResults.data?.dataFrame.fields ?? [];

    const onColumnChange: DropdownProps['onOptionSelect'] = (_event, evData) => {
        const selectedField = fields.find((field) => field.name === evData?.optionValue);
        if (selectedField) {
            onOptionSelect({ ColumnName: selectedField.name, ColumnType: selectedField.type });
        }
    };

    return (
        <Field label={<FieldLabel text={label ?? strings.dataExplorationModal.pillsBar.column} />}>
            <Dropdown
                placeholder={strings.dataExplorationModal.pillsBar.columnDropdownPlaceholder}
                onOptionSelect={onColumnChange}
                defaultValue={selectedColumn?.ColumnName}
                defaultSelectedOptions={selectedColumn ? [selectedColumn.ColumnName] : undefined}
                disabled={disabled}
                className={styles.popoverField}
            >
                {fields.map((field, i) => (
                    <Option key={field.name} data-testid={`column-dropdown-option-${i}`}>
                        {field.name}
                    </Option>
                ))}
            </Dropdown>
        </Field>
    );
};
