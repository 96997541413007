import type * as monacoKusto from '@kusto/monaco-kusto';
import isEmpty from 'lodash/isEmpty';
import { applySnapshot, flow, getParent, getRoot, IMSTMap, SnapshotIn, types } from 'mobx-state-tree';
import { IAnyType } from 'mobx-state-tree/dist/internal';

import { handleException } from '@kusto/app-common';
import { ColumnType, dotnetTypeToKustoType, KustoClientErrorDescription } from '@kusto/client';
import {
    convertInputParametersFunctionSymbol,
    fetchDatabaseSchema,
    fetchDatabaseSchemaAsEntities,
    getClusterSchemaOperations,
    getMaterializedViews,
    SchemaFetcher,
} from '@kusto/kusto-schema';
import type {
    DatabaseSchemaById,
    KustoDatabaseSchema,
    ShowDatabasesCommandResult,
    SuspensionState,
} from '@kusto/kusto-schema';
import {
    Account,
    AsyncResult,
    caseInsensitiveComparer,
    DataFrame,
    err,
    formatLiterals,
    ok,
    ResultWithAbort,
} from '@kusto/utils';

import { EntityType } from '../common';
import type { Column, EntityGroup, EntityGroupMember, Function, Table } from '../common';
import type { QueryLocale } from '../core/strings.ts';
import {
    BasicAccountInfo,
    ClusterType,
    CmSchema,
    ENTITY_GROUP_MEMBER_PREFIX,
    ENTITY_GROUP_PREFIX,
    FetchState,
    FUNCTION_PREFIX,
    ServiceOfferingType,
} from './common';
import { IRootStore } from './rootStore';
import { getQueryStoreEnv } from './storeEnv';

/**
 * Represents a Database in the kusto connection pane
 */
export const Database = types
    .model('Database', {
        id: types.identifier,
        name: types.string,
        prettyName: types.optional(types.union(types.string, types.undefined), undefined),
        fetchState: types.optional(FetchState, 'done'),
        fetchStateError: types.optional(types.frozen(), ''),
        tables: types.optional(types.map(types.frozen<Table>()), {}),
        // eslint-disable-next-line @typescript-eslint/ban-types
        functions: types.optional(types.map(types.frozen<Function>()), {}),
        entityGroups: types.optional(types.map(types.frozen<EntityGroup>()), {}),
        accessMode: types.maybe(types.string),
        minorVersion: types.number,
        majorVersion: types.number,
        suspensionState: types.maybe(types.frozen<SuspensionState>()),
    })
    .views((self) => ({
        get entityType(): EntityType.Database {
            return EntityType.Database;
        },
        /** Don't use this property/function! instead use the `getClusterFromDatabase()` which is type safe. */
        get cluster() {
            return getParent(self, 2) as { name: string };
        },
        get materializedViewTables(): Table[] {
            const tables = Array.from(self.tables.values());
            return tables.filter((table) => table.entityType === EntityType.MaterializedViewTable);
        },
        get externalTables(): Table[] {
            const tables = Array.from(self.tables.values());
            return tables.filter((table) => table.entityType === EntityType.ExternalTable);
        },
        get regularTables(): Table[] {
            const tables = Array.from(self.tables.values());
            return tables.filter((table) => table.entityType === EntityType.Table);
        },
    }))
    .volatile(() => ({
        isFetching: false,
        isBackgroundFetch: false,
        // eslint-disable-next-line @typescript-eslint/ban-types
        rowDataTypeCache: {} as { [id: string]: {} },
        mvQueriesIsFetching: false,
        mvQueriesEnrichState: 'notStarted' as FetchState,
        mvQueries: {} as { [tableId: string]: { query: string; sourceTableName: string } },
        /** http error code */
        mvQueriesFetchError: null as number | null,
    }))
    .actions((self) => {
        const getClusterDetails = () =>
            getParent(self, 2) as {
                name: string;
                connectionString: string;
                initialCatalog?: string;
                clusterUrl: string;
                fetchCurrentSchema: (a: boolean, b: boolean) => Promise<void>;
                getAccount_UNSAFE: () => Account | undefined;
                databases: Map<string, KustoDatabaseSchema>;
            };

        const { telemetry, kustoDomains } = getQueryStoreEnv(self);

        async function executeControlCommand(query: string, name?: string) {
            const { kustoClient, strings } = getQueryStoreEnv(self);
            const { initialCatalog, clusterUrl, getAccount_UNSAFE } = getClusterDetails();

            const result = await kustoClient
                .createRequest(clusterUrl, {
                    source: 'Query',
                })
                .executeControlCommand(name ?? initialCatalog, query, strings, {
                    account: getAccount_UNSAFE(),
                });

            if (result.kind !== 'ok') {
                return result;
            }

            return ok(result.value.frames[0].frame);
        }

        const enrichWithMVQueries = flow(function* () {
            try {
                const updateMaterializedViews = getQueryStoreEnv(self).kustoDomains.doesDomainSupportMaterializedViews(
                    getClusterDetails().clusterUrl
                );
                if (!updateMaterializedViews) {
                    return;
                }
                if (self.mvQueriesIsFetching) {
                    return;
                }
                self.mvQueriesIsFetching = true;
                const results: ResultWithAbort<DataFrame, KustoClientErrorDescription[]> = yield executeControlCommand(
                    `.show materialized-views`,
                    self.name
                );
                switch (results.kind) {
                    case 'abort':
                        self.mvQueriesFetchError = 0;
                        break;
                    case 'err':
                        onHttpErrorCallTrace(results.err, (httpError) => {
                            telemetry.error('database.enrichWithMVQueries.failed', {
                                ...httpError,
                            });
                        });
                        self.mvQueriesFetchError = results.err[0].httpStatusCode ?? null;
                        break;
                    case 'ok':
                        const materializedViews = getMaterializedViews(results.value);
                        materializedViews.forEach(({ name, sourceTable, query }) => {
                            const table = self.materializedViewTables.find((mv) => mv.name === name);
                            if (!table) {
                                return;
                            }
                            self.mvQueries[table.name] = { sourceTableName: sourceTable, query };
                        });
                        break;
                }
                self.mvQueriesEnrichState = 'done';
            } catch (ex) {
                self.mvQueriesFetchError = null;
                self.mvQueriesEnrichState = 'failed';
            }
            self.mvQueriesIsFetching = false;
        });

        /**
         * Fetch the current scheme. isBackgroundFetch will hide from the user the fact that a fetch is in progress.
         * This is useful in .create table commands that change the schema, and we would like to refresh the connection
         * pane without drawing too much attention to that fact.
         * @param isBackgroundFetch true if this should be done without displaying any visual loading indication
         * @param fetchParentsAsWell true if we should fetch cluster before fetching database.
         */
        const fetchCurrentSchema = flow(function* (isBackgroundFetch: boolean, fetchParentsAsWell: boolean) {
            if (self.isFetching) {
                return;
            }
            self.isFetching = true;
            self.isBackgroundFetch = isBackgroundFetch;
            const { kustoClient, telemetry, strings } = getQueryStoreEnv(self);
            const onComplete = () => {
                self.fetchState = 'done';
                self.isFetching = false;
            };

            try {
                const {
                    name: clusterName,
                    clusterUrl,
                    fetchCurrentSchema: fetchClusterSchema,
                    databases,
                    getAccount_UNSAFE,
                } = getClusterDetails();

                const suspendedDatabase =
                    self.entityType === EntityType.Database && isSuspendedDatabase(self.suspensionState);

                if (fetchParentsAsWell || suspendedDatabase) {
                    yield fetchClusterSchema(true, false);
                }

                if (suspendedDatabase) {
                    onComplete();
                    return;
                }

                const index = Array.from(databases.keys()).indexOf(self.id);
                if (index === -1) {
                    onError(strings, self, 'Database not found under cluster. Try cluster and database.');
                    telemetry.warn('database.fetchCurrentSchema.failed.indexOutOfBound', {
                        clusterUrl,
                        database: self.name,
                    });
                    return;
                }

                const liteSchemaExploration: boolean = !!(getRoot(self) as IRootStore).settings
                    .liteSchemaExplorationOverride as boolean;
                const schemaFetcher = new SchemaFetcher(
                    kustoDomains,
                    clusterUrl,
                    liteSchemaExploration,
                    () => {
                        return fetchDatabaseSchemaAsEntities(
                            kustoClient,
                            strings,
                            clusterUrl,
                            self.name,
                            telemetry,
                            getAccount_UNSAFE()
                        );
                    },
                    () => {
                        return fetchDatabaseSchema(kustoClient, strings, clusterUrl, self.name, getAccount_UNSAFE());
                    },
                    telemetry
                );
                const schemaResult: ResultWithAbort<KustoDatabaseSchema, KustoClientErrorDescription[]> =
                    yield schemaFetcher.fetchSchema({
                        clusterUrl,
                        database: self.name,
                    });
                if (schemaResult.kind === 'abort') {
                    onError(strings, self, 'Aborted fetching database schema');
                    return;
                }
                if (schemaResult.kind === 'err') {
                    onHttpError(strings, self, schemaResult.err, (httpError) => {
                        telemetry.trace('database.fetchCurrentSchema.failed.httpError', {
                            clusterUrl,
                            database: self.name,
                            ...httpError,
                        });
                    });
                    return;
                }

                const databaseSnapshot = convertDatabaseJsonSchemaToObjectModel(clusterName, schemaResult.value, index);
                self.rowDataTypeCache = {};
                onComplete();
                if (databaseSnapshot) {
                    applySnapshot(self, databaseSnapshot);
                }
            } catch (e: unknown) {
                const { clusterUrl } = getClusterDetails();
                const message = handleException({
                    t: strings,
                    telemetry,
                    exception: e,
                    telemetryName: 'database.fetchCurrentSchema.failed.exception',
                    properties: {
                        clusterUrl,
                        database: self.name,
                    },
                });
                onError(strings, self, message);
            }
        });

        const dropTable = flow(function* (table: Table) {
            const result: ResultWithAbort<DataFrame, KustoClientErrorDescription[]> = yield executeControlCommand(
                `.drop table ['${table.name}']`,
                self.name
            );
            fetchCurrentSchema(true, false);
            if (result.kind === 'abort') {
                return err('drop table aborted');
            }
            if (result.kind === 'err') {
                return err(result.err.map((e) => e.errorMessage).join('\n\n'));
            }
            return ok(true);
        });

        return {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setInCache(id: string, item: any) {
                self.rowDataTypeCache[id] = item;
            },
            getFromCache(id: string) {
                return self.rowDataTypeCache[id];
            },
            getDisplayName() {
                return self.prettyName || self.name;
            },
            fetchCurrentSchema,
            dropTable,
            enrichWithMVQueries,
        };
    })
    .volatile(() => ({
        _isFavorite: undefined as undefined | boolean,
    }))
    .extend((self) => ({
        views: {
            get isFavorite(): boolean {
                if (self._isFavorite === undefined) {
                    const connections = getParent(self, 4) as {
                        favorites: IMSTMap<IAnyType>;
                    };
                    return connections.favorites.has(self.id);
                }

                return self._isFavorite!;
            },
        },
        actions: {
            setIsFavorite(value: boolean) {
                self._isFavorite = value;
            },
        },
    }));
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Database = typeof Database.Type;

/**
 * Represents a kusto cluster in the connection Pane. including all operations on it (including functionality lie
 * refreshing schema)
 */
export const Cluster = types
    .model('Cluster', {
        clusterType: types.optional(ClusterType, 'Engine'),
        cmSchema: types.maybe(CmSchema),
        alias: types.maybe(types.string),
        name: types.string,
        connectionString: types.string,
        basicAccountInfo: types.maybe(BasicAccountInfo),
        initialCatalog: types.maybe(types.string),
        databases: types.optional(types.map(Database), {}),
        id: types.identifier,
        fetchState: types.optional(FetchState, 'notStarted'),
        fetchStateError: types.optional(types.frozen(), ''),
        tooBigToCache: types.optional(types.boolean, false),
        serviceOffering: types.optional(ServiceOfferingType, 'Azure Data Explorer'),
    })
    .actions((self) => {
        const { authProvider, featureFlags } = getQueryStoreEnv(self);
        return {
            getAccount_UNSAFE(): Account | undefined {
                if (!featureFlags.IFrameAuth && featureFlags.ClusterAccount && self.basicAccountInfo) {
                    const { username, tenantId } = self.basicAccountInfo;
                    const account = authProvider.getAccountByUsernameAndTenant(tenantId, username);
                    if (!account) {
                        throw new Error(`Login required`);
                    }
                    return account;
                }
                return undefined;
            },
            getAccount_SAFE(): Account | undefined {
                if (!featureFlags.IFrameAuth && featureFlags.ClusterAccount && self.basicAccountInfo) {
                    const { username, tenantId } = self.basicAccountInfo;
                    return authProvider.getAccountByUsernameAndTenant(tenantId, username);
                }
            },
        };
    })
    .views((self) => ({
        get clusterUrl(): string {
            return getClusterUrl(self.connectionString);
        },
        getAlias(): string {
            return isEmpty(self.alias) ? self.name : self.alias!;
        },
        isVirtualCluster: () => self.serviceOffering === 'Azure Data Explorer Personal',
        getDatabaseByName: (databaseName: string): Database | undefined => {
            const lowerCaseDbName = databaseName.toLowerCase();
            // look for the right database in name or pretty name (case insensitive)
            const databases = Array.from(self.databases.values());
            return databases.find(
                (candidateDb) =>
                    candidateDb.name.toLowerCase() === lowerCaseDbName ||
                    (!!candidateDb.prettyName && candidateDb.prettyName.toLowerCase() === lowerCaseDbName)
            );
        },
    }))
    .volatile(() => ({
        isFetching: false,
        isBackgroundFetch: false,
    }))
    .actions((self) => {
        const env = getQueryStoreEnv(self);
        const { kustoClient, telemetry } = env;
        const { fetchDatabases, fetchClusterSchema, showServiceModel, getClusterVersionInfo } =
            getClusterSchemaOperations(
                kustoClient,
                telemetry,
                self.clusterUrl,
                self.initialCatalog,
                self.getAccount_SAFE(),
                () => env.strings,
                env.kustoDomains
            );

        async function getSchemaUpdates(): AsyncResult<
            {
                tooBigToCache: boolean;
                databaseById: { [key: string]: SnapshotIn<typeof Database> };
            },
            KustoClientErrorDescription[]
        > {
            const { liteSchemaExplorationOverride, lazySchemaExploration } = (getRoot(self) as IRootStore).settings;
            const databasesResult = await fetchDatabases();
            if (databasesResult.kind !== 'ok') {
                return databasesResult;
            }

            const useLazySchemaExploration = lazySchemaExploration || databasesResult.value.length >= 50;
            const schemaResult = await fetchClusterSchema(
                useLazySchemaExploration,
                liteSchemaExplorationOverride,
                databasesResult.value
            );

            if (schemaResult.kind !== 'ok') {
                return schemaResult;
            }
            const { databaseSchemaById, tooBigToCache } = schemaResult.value;
            const databaseById = parseDatabaseListSchema(self.name, databasesResult.value, databaseSchemaById);
            return ok({
                tooBigToCache,
                databaseById,
            });
        }

        return {
            setIsFetching(value: boolean) {
                self.isFetching = value;
            },
            /** Skip caching schema for cluster snapshots */
            skipSchemaCache() {
                self.tooBigToCache = true;
            },

            /**
             * Fetch the current scheme. isBackgroundFetch will hide from the user the fact that a fetch is in progress.
             * This is useful in .create table commands that change the schema, and we would like to refresh the connection
             * pane without drawing too much attention to that fact.
             * @param isBackgroundFetch if true, we won't display a loading indicator on the entity.
             * @param fetchParentsAsWell in this case - it does nothing since Cluster is the top level. This is here for API
             * consistency.
             */
            fetchCurrentSchema: flow(function* fetchCurrentSchema(isBackgroundFetch: boolean) {
                if (self.isFetching) {
                    return;
                }
                self.isFetching = true;
                self.isBackgroundFetch = isBackgroundFetch;
                self.fetchStateError = undefined;
                const { telemetry, kustoDomains, strings } = getQueryStoreEnv(self);
                const onHttpErrorCluster = (httpErrors: KustoClientErrorDescription[]) => {
                    onHttpError(strings, self, httpErrors, (httpError) => {
                        telemetry.trace('cluster.fetchCurrentSchema.failed.httpError', {
                            clusterUrl: self.clusterUrl,
                            database: self.name,
                            ...httpError,
                        });
                    });
                };

                const onComplete = (clusterMap: unknown) => {
                    self.fetchState = 'done';
                    self.isFetching = false;
                    self.databases.replace(clusterMap);
                };

                try {
                    const url = getClusterUrl(self.connectionString);
                    if (!kustoDomains.isAriaDomain(url)) {
                        const clusterVersionInfo = yield getClusterVersionInfo();
                        if (clusterVersionInfo.kind === 'abort') {
                            onError(strings, self, 'aborted fetching version');
                            return;
                        }

                        if (clusterVersionInfo.kind === 'err') {
                            onHttpErrorCluster(clusterVersionInfo.err);
                            return;
                        }

                        const { clusterType, serviceOfferingType } = clusterVersionInfo.value;
                        self.clusterType = clusterType ?? self.clusterType;
                        if (serviceOfferingType) {
                            self.serviceOffering = serviceOfferingType;
                        } else {
                            telemetry.error('cluster.fetchCurrentSchema.serviceOfferingMissing', {
                                serviceOffering: self.serviceOffering,
                            });
                        }
                    }

                    if (self.clusterType === 'ClusterManager') {
                        const result = yield showServiceModel();
                        if (result.kind === 'abort') {
                            onError(strings, self, 'aborted fetching cm schema');
                            return;
                        }

                        if (result.kind === 'err') {
                            onHttpErrorCluster(result.err);
                            return;
                        }

                        const { accounts, services } = result.value;
                        self.cmSchema = CmSchema.create({
                            accounts,
                            services,
                        });

                        onComplete({});
                        return;
                    }

                    // No dynamic schema for Non CM/Engine clusters.
                    if (self.clusterType !== 'Engine') {
                        onComplete({});
                        return;
                    }

                    const result: ResultWithAbort<
                        {
                            tooBigToCache: boolean;
                            databaseById: { [key: string]: SnapshotIn<typeof Database> };
                        },
                        KustoClientErrorDescription[]
                    > = yield getSchemaUpdates();

                    if (result.kind === 'abort') {
                        onError(strings, self, 'aborted fetching cluster schema');
                        return;
                    }

                    if (result.kind === 'err') {
                        onHttpErrorCluster(result.err);
                        return;
                    }

                    const { tooBigToCache, databaseById } = result.value;
                    self.tooBigToCache = tooBigToCache;
                    self.databases.replace(databaseById);
                    self.fetchState = 'done';
                    self.isFetching = false;
                } catch (e: unknown) {
                    const message = handleException({
                        t: strings,
                        telemetry,
                        exception: e,
                        telemetryName: 'cluster.fetchCurrentSchema.failed.exception',
                        properties: {
                            clusterUrl: self.clusterUrl,
                            database: self.name,
                        },
                    });
                    onError(strings, self, message);
                }
            }),
        };
    })
    .volatile(() => ({
        _isFavorite: undefined as undefined | boolean,
    }))
    .extend((self) => ({
        views: {
            get entityType(): EntityType.Cluster {
                return EntityType.Cluster;
            },
            get isFavorite(): boolean {
                if (self._isFavorite === undefined) {
                    const connections = getParent(self, 2) as {
                        favorites: IMSTMap<IAnyType>;
                    };
                    return connections.favorites.has(self.id);
                }

                return self._isFavorite!;
            },
        },
        actions: {
            setIsFavorite(value: boolean) {
                self._isFavorite = value;
            },
            setAlias(value?: string) {
                self.alias = value;
            },
            setBasicAccountInfo(basicAccountInfo?: BasicAccountInfo) {
                self.basicAccountInfo = basicAccountInfo;
            },
        },
    }));

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Cluster = typeof Cluster.Type;

/** Get the Cluster from a Database entity */
export function getClusterFromDatabase(database: Database) {
    return database.cluster as Cluster;
}

/**
 * Convert the mobx-state-tree Database type into a Monaco-Kusto's database symbol.
 */
export const convertDatabaseToDatabaseSymbol = (
    db: Database,
    entityInContextAsDB: Database,
    dbInContextName: string,
    dnInContextMvQueriesEnrichNotStarted: boolean
): monacoKusto.Database => {
    let majorVersion = db.fetchState === 'notStarted' ? db.majorVersion - 1 : db.majorVersion;
    const isDbInContext = dbInContextName === db.name;
    if (isDbInContext && (entityInContextAsDB.mvQueriesIsFetching || dnInContextMvQueriesEnrichNotStarted)) {
        // Setting major version with a lower value, so setSchema
        // will not take the data from cache after setting with
        // mvQueries
        majorVersion = majorVersion - 1;
    }

    return {
        name: db.name,
        alternateName: db.prettyName,
        majorVersion,
        minorVersion: db.minorVersion,
        tables: Array.from(db.tables.values()).map((table) => ({
            name: table.name,
            docstring: table.docstring,
            entityType: table.entityType,
            columns: Object.values(table.columns).map((col) => ({
                name: col.name,
                type: col.type,
                docstring: col.docstring,
            })),
            mvQuery:
                !isDbInContext || dnInContextMvQueriesEnrichNotStarted
                    ? undefined
                    : entityInContextAsDB.mvQueries[table.name]?.query,
        })),
        functions: Array.from(db.functions.values()),
        entityGroups: Object.values(db.entityGroups.toJSON()).map((entry) => ({
            name: entry.name,
            members: Object.values(entry.entities).map((entity) => entity.name),
        })),
    };
};

const convertDatabaseRowToObjectModel = (
    clusterName: string,
    database: ShowDatabasesCommandResult
): Partial<Database> => {
    const databaseId = `${clusterName}/${database.name}`;
    // intellisense library relies on db versions when deciding to cache things.
    // Since this is a lazy DB, we want the intellisense to refresh the moment we get
    // a real database version.
    const majorVersion = -1;
    const minorVersion = -1;
    return {
        id: databaseId,
        name: database.name,
        prettyName: database.prettyName,
        minorVersion,
        majorVersion,
        accessMode: database.accessMode,
        suspensionState: database.suspensionState,
        fetchState: isSuspendedDatabase(database.suspensionState) ? 'done' : 'notStarted',
    };
};

/**
 * Convert Database Json (Json returned from .show schema) into an object model that can be applied (using applySnapshot) to the mobx-state-tree Database type.
 */
const convertDatabaseJsonSchemaToObjectModel = (
    clusterName: string,
    db: KustoDatabaseSchema,
    dbIndex: number
): SnapshotIn<Database> => {
    const {
        MajorVersion: majorVersion,
        MinorVersion: minorVersion,
        PrettyName: prettyName,
        Name: name,
        Tables: tableSchemas,
        ExternalTables: externalTables,
        MaterializedViews: materializedViews,
        DatabaseAccessMode: accessMode,
        EntityGroups: schemaEntityGroups,
        Functions: schemaFunctions,
    } = db;

    const databaseId = `${clusterName}/${name}`;
    const databaseIdPrefix = `${clusterName}/${dbIndex}`;
    const allTables = Object.assign({}, tableSchemas, externalTables, materializedViews || {});

    const tables: { [i: string]: Table } = Object.entries(allTables).reduce((acc, [name, table], tableIndex) => {
        const tableId = `${databaseId}/${name}`;
        const tableIdPrefix = `${databaseIdPrefix}/${tableIndex}`;
        const { Folder: folder, tableType: entityType, DocString: docstring, OrderedColumns: orderedColumns } = table;

        const columns = orderedColumns.reduce(
            (acc, { Name: name, CslType: cslType, Type: type, DocString: docstring }, columnIndex) => {
                const columnId = `${tableIdPrefix}/${columnIndex}`;
                acc[columnId] = {
                    id: columnId,
                    name,
                    docstring,
                    entityType: EntityType.Column as EntityType.Column,
                    // Maybe not correct? `dotnetTypeToKustoType` has properties that aren't of `ColumnType`.
                    type: (cslType || dotnetTypeToKustoType[type] || type) as ColumnType,
                };
                return acc;
            },
            {} as { [i: string]: Column }
        );

        acc[tableId] = {
            id: tableId,
            entityType,
            name,
            columns,
            folder,
            docstring,
        };

        return acc;
    }, {} as { [i: string]: Table });

    const entityGroups = Object.entries(schemaEntityGroups ?? {}).reduce(
        (acc, [entityGroupName, entityGroup], egIndex) => {
            const id = `${ENTITY_GROUP_PREFIX}${databaseIdPrefix}/${egIndex}`;
            acc[id] = {
                id,
                entityType: EntityType.EntityGroup,
                name: entityGroupName,
                entities: entityGroup
                    .map((entity) => {
                        return {
                            id: `${ENTITY_GROUP_MEMBER_PREFIX}${databaseId}/${entityGroupName}/${entity}`,
                            name: entity,
                            entityType: EntityType.EntityGroupMember,
                        } as EntityGroupMember;
                    })
                    .reduce<{ [i: string]: EntityGroupMember }>((prev, curr) => {
                        prev[curr.id] = curr;
                        return prev;
                    }, {}),
            };
            return acc;
        },
        {} as { [i: string]: EntityGroup }
    );

    const functions = Object.values(schemaFunctions ?? {}).reduce((acc, fn, fnIndex) => {
        const id = `${FUNCTION_PREFIX}${databaseIdPrefix}/${fnIndex}`;
        acc[id] = {
            id,
            entityType: fn.FunctionKind === 'ViewFunction' ? EntityType.ViewFunction : EntityType.Function,
            body: fn.Body,
            docstring: fn.DocString,
            folder: fn.Folder,
            functionKind: fn.FunctionKind,
            name: fn.Name,
            outputColumns: fn.OutputColumns,
            inputParameters: fn.InputParameters ? fn.InputParameters.map(convertInputParametersFunctionSymbol) : [],
        };
        return acc;
        // eslint-disable-next-line @typescript-eslint/ban-types
    }, {} as { [i: string]: Function });

    return {
        name,
        prettyName,
        minorVersion,
        majorVersion,
        id: databaseId,
        tables,
        functions,
        entityGroups,
        accessMode,
    };
};

/**
 * Gets the values returned from ".show schema as json" and ".show databases"
 * return a dictionary of databases by their id. database schema may or may nor be populated.
 */
export function parseDatabaseListSchema(
    clusterName: string,
    databasesList: ShowDatabasesCommandResult[],
    databaseSchemaById: DatabaseSchemaById | undefined
): { [key: string]: SnapshotIn<typeof Database> } {
    return databasesList
        .sort((databaseA, databaseB) => caseInsensitiveComparer(databaseA.name, databaseB.name))
        .filter(({ name }) => name !== 'KustoMonitoringPersistentDatabase' && name !== '$systemdb') // We don't want to show internal databases.
        .reduce((databaseById, showDatabaseResult, dbIndex) => {
            const database =
                isSuspendedDatabase(showDatabaseResult.suspensionState) ||
                !databaseSchemaById?.[showDatabaseResult.name]
                    ? (convertDatabaseRowToObjectModel(clusterName, showDatabaseResult) as SnapshotIn<Database>)
                    : convertDatabaseJsonSchemaToObjectModel(
                          clusterName,
                          databaseSchemaById[showDatabaseResult.name],
                          dbIndex
                      );

            databaseById[database.id] = database;
            return databaseById;
        }, {} as { [key: string]: SnapshotIn<typeof Database> });
}

/**
 * A convenience method to normalize cluster or a database to a cluster, database pair).
 * @param entity the Entity
 */
export const getClusterAndDatabaseFromEntity = (
    entity: Cluster | Database
): { cluster: Cluster; database: Database | null } => {
    switch (entity.entityType) {
        case 'Cluster':
            return {
                cluster: entity as Cluster,
                database: null,
            };
        case 'Database':
            const database = entity as Database;
            const cluster = getClusterFromDatabase(database);
            return {
                cluster,
                database,
            };
        default:
            throw new Error(`unexpected entityType ${entity.entityType}`);
    }
};

export const getClusterUrl = (url: string): string => url.split(';')[0];

export const isTridentCluster = (cluster: Cluster): boolean => cluster.serviceOffering === 'Trident';

export function isSuspendedDatabase(suspensionState: SuspensionState | undefined) {
    return !!suspensionState?.CMKSuspensionStart;
}

interface FetchStateWrapper {
    fetchState: FetchState;
    isFetching: boolean;
    fetchStateError: KustoClientErrorDescription;
}

function onError(strings: QueryLocale, self: FetchStateWrapper, fetchStateError: string | KustoClientErrorDescription) {
    self.fetchState = 'failed';
    self.isFetching = false;
    if (typeof fetchStateError === 'string') {
        self.fetchStateError = {
            errorMessage: formatLiterals(strings.query.schema.schemaFetchFailed, { errorText: fetchStateError }),
        };
    } else {
        self.fetchStateError = {
            ...fetchStateError,
            errorMessage: formatLiterals(strings.query.schema.schemaFetchFailed, {
                errorText: fetchStateError.errorMessage,
            }),
        };
    }
}

const onHttpErrorCallTrace = (
    httpErrors: KustoClientErrorDescription[],
    onTrace: (httpError: Omit<KustoClientErrorDescription, 'token'> & { currentStack: string | undefined }) => void
) => {
    const messages: string[] = [];
    httpErrors.forEach((httpError) => {
        messages.push(httpError.errorMessage);
        const { token, ...httpErrorWithoutToken } = httpError;
        onTrace({
            ...httpErrorWithoutToken,
            currentStack: new Error().stack,
        });
    });
    return messages;
};

const onHttpError = (
    strings: QueryLocale,
    self: FetchStateWrapper,
    httpErrors: KustoClientErrorDescription[],
    onTrace: (httpError: Omit<KustoClientErrorDescription, 'token'> & { currentStack: string | undefined }) => void
) => {
    const messages = onHttpErrorCallTrace(httpErrors, onTrace);
    onError(strings, self, {
        ...httpErrors[0],
        errorMessage: messages.join('\n\n'),
    });
};
