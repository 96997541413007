import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, CancelToken } from 'axios';
//
// Json response from kusto service might include numbers that are bigger than javascript MAX_INT
// When using the default JSON.parse they will be converted to number and lose precision
// issue known as BigInt/BigInteger
//
// In order to overcome the issue, numbers will be kept as String and displayed as string as much as possible
// Added while enabling lints
// eslint-disable-next-line @typescript-eslint/no-var-requires
import JSONbigInt from 'json-bigint';
import merge from 'lodash/merge';

import {
    ABORTED,
    Account,
    AsyncResult,
    castToError,
    DataFrame,
    err,
    Field,
    IKweTelemetry,
    InterfaceFor,
    KweException,
    ok,
    Result,
    UField,
} from '@kusto/utils';

import { dynamicEscapeProxyHostnames } from '../constants';
import { clientV2ResultToDataFrame, dataFrameValueFromClientValue, kweDataTypeFromExtended } from '../dataFrameUtil';
import { KustoClientError } from '../KustoClientError';
import type { KustoDomains } from '../KustoDomains';
import { IKustoClientAuthProvider, KustoClientLocale, VisualizationOptions } from '../types';
import { extractErrorDescription } from './extractErrorInfo__deprecated';
import {
    ApiVersion,
    buildClientURL,
    CancellationToken,
    CancellationTokenSource,
    ClientRequestProperties,
    DataSetCompletion,
    DataTable,
    ErrorRowV1,
    ExecutionResult,
    KustoClientEvents,
    KustoClientResult,
    KustoClientResultV2,
    OneApiError,
    RequestHeaders,
    RowV1,
    V1TableKind,
    ValueRow,
} from './KustoClient';
import { kustoOneApiErrorToInfo } from './kustoOneApiErrorToInfo';
import { isV1Response, v1TypeToKustoType } from './lib';

const configuredBigInt = JSONbigInt({ storeAsString: true, protoAction: 'preserve', constructorAction: 'preserve' });

const HttpCodes = {
    unauthorized: 401,
};

interface XMsHeadersParams {
    appName: string;
    clientRequestId: string;
}

export interface KustoClientErrorDescription {
    code?: string;
    errorMessage: string;
    type?: string;
    line?: number;
    pos?: number;
    token?: string;
    clientRequestId?: string;
    httpStatusCode?: number;
    permanent?: boolean;
}

export interface KustoApiFrame {
    readonly frame: DataFrame;
    readonly errors: undefined | readonly KustoClientErrorDescription[];
}

export interface KustoQueryResult {
    readonly frames: readonly KustoApiFrame[];
    readonly visualizationOptions?: VisualizationOptions;
    readonly errors: undefined | readonly KustoClientErrorDescription[];
}

/**
 * Removes errors from v1 rows
 */
function splitV1Rows(rows: RowV1[]): { rows: ValueRow[]; errors?: string[] } {
    if (rows.length !== 0 && !Array.isArray(rows[rows.length - 1])) {
        return { rows: rows as ValueRow[], errors: (rows.pop() as ErrorRowV1).Exceptions };
    }
    return { rows: rows as ValueRow[] };
}

function normalizeV1PrimaryResult(
    table: KustoClientResult['Tables'][number],
    applyApplicationInsightsHack: undefined | { onAppInsightsFixed: () => void }
): KustoApiFrame {
    const { rows, errors } = splitV1Rows(table.Rows);

    const fields: UField[] = table.Columns.map((c, i) => {
        const type = kweDataTypeFromExtended(c.ColumnType || v1TypeToKustoType[c.DataType]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return new Field<any, any>(
            c.ColumnName,
            type,
            rows.map((r) => dataFrameValueFromClientValue((r as ValueRow)[i], type, applyApplicationInsightsHack))
        );
    });

    return { frame: { fields, size: rows.length }, errors: errors?.map((e) => ({ errorMessage: e })) };
}

function parseVisualOptions(visualOptions: unknown): VisualizationOptions {
    // Visualization options doesn't need to be parsed for Kusto
    // responses when `query_results_cache_max_age` causes a cache
    // hit
    return typeof visualOptions === 'string' ? JSON.parse(visualOptions) : visualOptions;
}

/**
 * Only used for parsing the results of control commands, so this does not
 * support multiple table results
 *
 *  https://learn.microsoft.com/en-us/azure/data-explorer/kusto/api/rest/response
 */
export function normalizeResultV1(
    arg: KustoClientResult,
    telemetry: IKweTelemetry,
    applyApplicationInsightsHack: undefined | { onAppInsightsFixed: () => void }
): Result<KustoQueryResult, KustoClientErrorDescription[]> {
    // For some (undocumented?) reason, some v1 results only contain a single
    // primary result table, and not table of contents. Management responses are
    // always like this, but it can occur with other results as well
    if (arg.Tables.length === 1) {
        return ok({
            frames: [normalizeV1PrimaryResult(arg.Tables[0], applyApplicationInsightsHack)],
            errors: undefined,
        });
    }

    let visualizationOptions: undefined | VisualizationOptions;

    const frames: KustoApiFrame[] = [];

    //  See "TableOfContents table" here:
    //  https://learn.microsoft.com/en-us/azure/data-explorer/kusto/api/rest/response#the-meaning-of-tables-in-the-response
    const tocTable = arg.Tables[arg.Tables.length - 1];

    const tableKindIndex = tocTable.Columns.findIndex((c) => c.ColumnName === 'Kind');

    for (let i = 0; i < tocTable.Rows.length; i++) {
        const kind = (tocTable.Rows[i] as ValueRow)[tableKindIndex] as V1TableKind;
        const table = arg.Tables[i];
        switch (kind) {
            case 'QueryResult':
                frames.push(normalizeV1PrimaryResult(table, applyApplicationInsightsHack));
                break;
            case 'QueryProperties': {
                if (visualizationOptions !== undefined) {
                    throw new KweException(`QueryProperties TableKind unexpectedly occurs twice`);
                }

                visualizationOptions = parseVisualOptions((table.Rows[0] as ValueRow)[0]);
            }
        }
    }

    // As far as I know, V1 responses always include tables, so no need to
    // handle tables.length === 0
    if (frames.length === 0) {
        telemetry.exception('No tables in V1 response', { hasExceptions: !!arg.Exceptions });
    }

    return ok({ frames, visualizationOptions, errors: undefined });
}

/**
 * https://learn.microsoft.com/en-us/azure/data-explorer/kusto/api/rest/response2
 */
function normalizeResultV2(
    arg: KustoClientResultV2,
    telemetry: IKweTelemetry,
    applyApplicationInsightsHack: undefined | { onAppInsightsFixed: () => void }
): Result<KustoQueryResult, KustoClientErrorDescription[]> {
    const dataSetCompletion = arg[arg.length - 1] as DataSetCompletion;

    const frames: KustoApiFrame[] = [];
    let visualizationOptions: undefined | VisualizationOptions;

    // Loop through everything but the first and last tables
    for (let i = 1; i < arg.length - 1; i++) {
        const frame = arg[i] as DataTable;
        switch (frame.TableKind) {
            case 'PrimaryResult':
                // If the query contained any errors, the error will be
                // duplicated at the bottom of the matching result. This line
                // removes it.
                if (!Array.isArray(frame.Rows[frame.Rows.length - 1])) {
                    frame.Rows.pop();
                }

                frames.push({
                    frame: clientV2ResultToDataFrame(frame, applyApplicationInsightsHack),
                    errors: undefined,
                });
                break;
            case 'QueryProperties': {
                if (visualizationOptions !== undefined) {
                    throw new KweException(`QueryProperties TableKind unexpectedly occurs twice`);
                }
                let keyColumnIndex!: number;
                let valueColumnIndex!: number;
                for (let i = 0; i < frame.Columns.length; i++) {
                    switch (frame.Columns[i].ColumnName) {
                        case 'Key':
                            keyColumnIndex = i;
                            break;
                        case 'Value':
                            valueColumnIndex = i;
                    }
                }

                // TODO: Include all visual options, not just the first we find
                // Sorta related task: https://msazure.visualstudio.com/One/_workitems/edit/26830833
                const visualOptionsRow = (frame.Rows as ValueRow[]).find(
                    (row) => row[keyColumnIndex] === 'Visualization'
                )!;
                visualizationOptions = parseVisualOptions(visualOptionsRow[valueColumnIndex]);
            }
        }
    }

    const errors = dataSetCompletion.HasErrors
        ? dataSetCompletion.OneApiErrors.map((e) =>
              kustoOneApiErrorToInfo(e, telemetry, applyApplicationInsightsHack !== undefined)
          )
        : undefined;

    if (frames.length === 0) {
        if (!errors) {
            throw new KweException('No results or errors in query response');
        }
        return err(errors);
    }

    // Narrowly match a specific case where we disagree with Kusto retuning a
    // result alongside an error. Ideally this would be fixed in the backend,
    // not here.
    //
    // Match should be as narrow as possible because it misrepresents what the
    // backend returned to the user, so we really don't want it to match
    // anything unexpected.
    if (
        errors?.length === 1 &&
        errors[0].type === 'Kusto.DataNode.Exceptions.UnauthorizedDatabaseAccessException' &&
        frames.every((f) => f.frame.size === 0 && !f.errors)
    ) {
        // Log trace so we can tell when this hack can be deleted
        telemetry.trace('Unauthorized partial to full error correction');
        return err(errors);
    }

    return ok({ frames, visualizationOptions, errors });
}

export type IKustoRequest = InterfaceFor<KustoRequest>;

/**
 * A kusto request.
 *
 * How to use KustoRequest:
 * 1. Trigger an executeXXX method once per KustoRequest (Calling KustoRequest only once is not enforced at the moment but it may cause the request to fail by the cluster).
 * 2. Call cancelQuery if needed.
 */
export class KustoRequest {
    /**
     * The client request ID.
     * - will be passed in the header as x-ms-client-request-id.
     * - Will be passed in a cancel request ".cancel query <this.clientRequestId>".
     */
    readonly clientRequestId: string;

    /**
     * The app name, will be passed in the header as x-ms-app.
     */
    private readonly appName: string;

    constructor(
        /** Used to validate that the request goes to an approved kusto domain  */
        private readonly domains: KustoDomains,
        /** Used to fetch the access token for request's authorization, and the account for x-ms-user-id */
        private readonly authenticationProvider: IKustoClientAuthProvider,
        /** getToken will be called with the cluster scope or if false, will be called with none **/
        private readonly useClusterScope: boolean,
        /** x-ms-* headers */
        xMsHeadersParams: XMsHeadersParams,
        /** The cluster's URL */
        private readonly url: string,
        private readonly telemetry: IKweTelemetry,
        /** A set of events that will be triggered during the lifetime of the request. */
        private readonly events?: KustoClientEvents,
        /** A set of default properties to set as part of the request. */
        private readonly getAmbientRequestProperties?: (
            isQuery: boolean
        ) => Promise<Partial<ClientRequestProperties> | null>,
        private readonly onQueryExecuted?: (result: ExecutionResult<'v1' | 'v2'> | KustoClientError) => void
    ) {
        this.appName = xMsHeadersParams.appName;
        this.clientRequestId = xMsHeadersParams.clientRequestId;
    }

    private parseResponse(apiCallResult: unknown) {
        const responseType = typeof apiCallResult;
        const start = performance.now();
        if (typeof apiCallResult === 'string' && apiCallResult?.length > 0) {
            return configuredBigInt.parse(apiCallResult);
        }

        if (this.events?.responseParsed) {
            this.events?.responseParsed(responseType, performance.now() - start, length);
        }
    }

    /**
     * Return the value for the HTTP header x-ms-user-id. Expects `this.authenticationProvider.getUser` to return a User.
     */
    private async getUserIDHeader(account?: Account): Promise<string | undefined> {
        if (!account && !this.authenticationProvider.getAccount) {
            return undefined;
        }
        const noAsciiValuesRegex = /[^\x20-\x7E]+/g;
        const requestAccount = account || (await this.authenticationProvider.getAccount());
        const userNameAsciiOnly = requestAccount?.name?.replace(' ', '_').replace(noAsciiValuesRegex, '');
        let uid = userNameAsciiOnly;

        // if user name contains ascii characters take the user name from the user's email (upn).
        if (!userNameAsciiOnly || requestAccount?.name?.length !== userNameAsciiOnly.length) {
            const userEmail = requestAccount?.username;
            const userAlias = userEmail?.split('@')[0].replace(noAsciiValuesRegex, '');
            if (userAlias) {
                uid = userAlias;
            }
        }
        return uid ?? undefined;
    }

    /**
     * @deprecated in favor of {@link KustoRequest.execute}
     *
     * Execute a query or a command query against a Kusto cluster
     * @param dbName The name of the database
     * @param queryOrCommand  The query or command to run. (commands starts with `.`, like `.show schema`)
     * @param isQuery is it a query or command
     * @param apiVersion Which API Version to use. Currently command queries are only supported by v1.
     * @param options Provides client request properties that modify how the request is processed and its results. For more information, see client request properties. @see https://docs.microsoft.com/en-us/azure/data-explorer/kusto/api/netfx/request-properties#clientrequestproperties-options.
     * @param options.properties Provides client request properties that modify how the request is processed and its results. For more information, see client request properties. @see https://docs.microsoft.com/en-us/azure/data-explorer/kusto/api/netfx/request-properties#clientrequestproperties-options.
     * @param options.requestTimeout Timeout before the request is cancelled.
     * @param options.cancelToken Cancellation token created with createCancelToken.
     * @param options.retryCount On auth failure how many retries to perform. Default is one.
     * @param options.account The account to use to authorize the request. If account is not passed, use the default account in the authentication provider (the logged in user).
     * @param options.onStartHttpRequest a callback that is triggered after a token is fetched but before the request has been sent.
     */
    async execute_deprecated<T extends ApiVersion>(
        dbName: string | undefined,
        queryOrCommand: string,
        isQuery: boolean,
        apiVersion: T,
        options: {
            properties?: ClientRequestProperties;
            requestTimeout?: number;
            requestTimeoutMessage?: string;
            cancelToken?: CancellationToken;
            retryCount?: number;
            account?: Account;
            onStartHttpRequest?: () => void;
        }
    ): Promise<T extends 'v1' ? ExecutionResult<'v1'> : ExecutionResult<'v2'>> {
        try {
            const { properties, requestTimeout, requestTimeoutMessage, cancelToken, retryCount = 0, account } = options;

            const urlObj = new URL(this.url);
            if (!this.domains.isUrlKustoSubDomain(urlObj)) {
                const targetDomain = urlObj.hostname;
                this.events?.nonKustoDomain?.(targetDomain);
                throw new Error(`Domain '${targetDomain}' is not a valid kusto domain.`);
            }
            const uid = (await this.getUserIDHeader(account)) || 'unknown';
            const isLocalDevCluster = this.domains.isLocalDevDomain(this.url);

            let token = '';
            if (!isLocalDevCluster) {
                const scopes = await this.authenticationProvider.getClusterScopes(this.url);
                const tokenRes = await this.authenticationProvider.getToken(scopes, account);
                if (tokenRes.kind === 'err') {
                    throw tokenRes.err;
                }
                token = tokenRes.value;
            }

            if (this.events?.tokenFetched) {
                this.events.tokenFetched(
                    this.clientRequestId,
                    window.location.href,
                    this.url,
                    dbName || 'N/A',
                    isQuery ? 'query' : 'command',
                    queryOrCommand
                );
            }

            if (options.onStartHttpRequest) {
                options.onStartHttpRequest();
            }

            const httpRequestStartTime = new Date();
            const urlWithQuery = buildClientURL(this.url, apiVersion, isQuery);
            const ambientRequestProperties = await this.getAmbientRequestProperties?.(isQuery);
            const data = {
                db: dbName,
                csl: queryOrCommand,
                // per-request properties override ambient properties. we're doing deep merge here since ClientRequestProperties has some hierarchy.
                properties: ambientRequestProperties
                    ? merge({}, ambientRequestProperties, properties)
                    : properties
                    ? properties
                    : null,
            };

            const headers: AxiosRequestHeaders = {
                'Content-Type': 'application/json; charset=utf-8',
                'x-ms-app': this.appName,
                'x-ms-client-request-id': this.isCancelQueryCommand(queryOrCommand)
                    ? `${this.clientRequestId};cancelled`
                    : this.clientRequestId,
                'x-ms-user-id': uid,
                Accept: 'application/json',
            };

            if (!isLocalDevCluster) {
                headers['Authorization'] = `Bearer ${token}`;
            }

            const config: AxiosRequestConfig = {
                headers,
                transformResponse: [this.parseResponse],
                cancelToken: cancelToken,
                timeout: requestTimeout,
                timeoutErrorMessage:
                    requestTimeoutMessage ??
                    'Network Error: Please check your internet connection. You may need to connect to the VPN',
            };

            let axiosResponse: AxiosResponse<unknown>;
            try {
                axiosResponse = await axios.post(urlWithQuery, data, config);
            } catch (e) {
                const isCancel = axios.isCancel(e);
                const isAxiosError = axios.isAxiosError(e);
                if (!isAxiosError) {
                    const errorMessage = extractErrorDescription(e).errorMessage;
                    throw new KustoClientError(e, errorMessage, false, isCancel, this.clientRequestId);
                }
                if (e.response?.status === HttpCodes.unauthorized && retryCount === 0) {
                    // authorization error
                    if (this.authenticationProvider.refreshToken) {
                        this.events?.onUnauthorizedError?.(this.clientRequestId, true, true, retryCount + 1, e);
                        try {
                            await this.authenticationProvider.refreshToken(undefined, account);
                            return this.execute_deprecated<T>(dbName, queryOrCommand, isQuery, apiVersion, {
                                properties,
                                requestTimeout,
                                requestTimeoutMessage,
                                cancelToken,
                                retryCount: retryCount + 1,
                            });
                        } catch (e) {
                            this.events?.onUnauthorizedError?.(
                                this.clientRequestId,
                                true,
                                true,
                                retryCount + 1,
                                castToError(e)
                            );
                        }
                    }
                }

                if (e.response?.status === HttpCodes.unauthorized && this.events?.onUnauthorizedError) {
                    this.events?.onUnauthorizedError(this.clientRequestId, false, false, retryCount, e);
                }

                if (e.response?.data) {
                    throw new KustoClientError(
                        e.response?.data,
                        e.message,
                        isAxiosError,
                        isCancel,
                        this.clientRequestId,
                        e.response,
                        e.response.data
                    );
                } else {
                    // Since we don't have axios timeout error, we are using network error as timeout error
                    throw new KustoClientError(e, e.message, isAxiosError, isCancel, this.clientRequestId, e.response);
                }
            }

            const result = {
                apiCallResult: axiosResponse?.data,
                httpRequestStartTime,
                clientRequestId: this.clientRequestId,
            } as T extends 'v1' ? ExecutionResult<'v1'> : ExecutionResult<'v2'>;

            this.onQueryExecuted?.(result);

            return result;
        } catch (e) {
            this.onQueryExecuted?.(e as KustoClientError);
            throw e;
        }
    }

    private isCancelQueryCommand(queryOrCommand: string): boolean {
        return queryOrCommand.startsWith('.cancel query');
    }

    /**
     * @deprecated in favor of {@link KustoRequest.executeControlCommand}
     */
    executeControlCommand_deprecated(
        dbName: string | undefined,
        command: string,
        options: {
            account?: Account;
            properties?: ClientRequestProperties;
            requestTimeout?: number;
            requestHeaders?: RequestHeaders;
            cancelToken?: CancelToken;
        } = {}
    ): Promise<ExecutionResult<'v1'>> {
        return this.execute_deprecated(dbName, command, false, 'v1', options);
    }

    /**
     * @deprecated in favor of {@link KustoRequest.executeQuery}
     */
    executeQuery_deprecated(
        dbName: string,
        query: string,
        options: {
            properties?: ClientRequestProperties;
            requestHeaders?: RequestHeaders;
            cancelToken?: CancelToken;
        } = {}
    ): Promise<ExecutionResult<'v1'>> {
        return this.execute_deprecated(dbName, query, true, 'v1', options);
    }

    /**
     * @deprecated in favor of {@link KustoRequest.executeQuery}
     */
    executeQueryV2_deprecated(
        dbName: string,
        query: string,
        options: {
            account?: Account;
            properties?: ClientRequestProperties;
            requestHeaders?: RequestHeaders;
            cancelToken?: CancelToken;
        } = {}
    ): Promise<ExecutionResult<'v2'>> {
        return this.execute_deprecated(dbName, query, true, 'v2', options);
    }

    /**
     * @deprecated in favor of {@link KustoRequest.cancelQuery}
     */
    async cancelQuery_deprecated(requestTimeout?: number): Promise<ExecutionResult<'v1'>> {
        return this.executeControlCommand_deprecated(undefined, `.cancel query '${this.clientRequestId}'`, {
            requestTimeout,
        });
    }

    /**
     * Execute a query or a command query against a Kusto cluster
     *
     * @param dbName The name of the database
     * @param queryOrCommand  The query or command to run. (commands starts with
     * `.`, like `.show schema`)
     * @param isQuery is it a query or command
     * @param apiVersion Which API Version to use. Currently command queries are
     * only supported by v1.
     * @param t The locale to use for error messages
     * @param signal When AbortSignal is fired, request is canceled
     * @param options Provides client request properties that modify how the
     * @param options.properties Provides client request properties that modify
     * how the request is processed and its results. For more information, see
     * client request properties. @see
     * https://docs.microsoft.com/en-us/azure/data-explorer/kusto/api/netfx/request-properties#clientrequestproperties-options.
     * @param options.signal When AbortSignal is fired, request is canceled
     * @param options.requestTimeout Timeout before the request is cancelled.
     * @param options.requestTimeoutMessage Message to show when request is timed out.
     * @param options.cancelToken Cancellation token created with
     * createCancelToken.
     * @param options.retryCount On auth failure how many retries to perform.
     * Default is one.
     * @param options.account The account to use to authorize the request. If
     * account is not passed, use the default account in the authentication
     * provider (the logged-in user).
     * @param options.onStartHttpRequest a callback that is triggered after a
     * token is fetched but before the request has been sent.
     */
    async execute(
        dbName: string | undefined,
        queryOrCommand: string,
        isQuery: boolean,
        apiVersion: 'v1' | 'v2',
        t: KustoClientLocale,
        {
            signal,
            requestTimeoutMessage = t.utils.util.error.requestTimeoutMessage,
            ...options
        }: {
            properties?: ClientRequestProperties;
            signal?: AbortSignal;
            requestTimeout?: number;
            requestTimeoutMessage?: string;
            cancelToken?: CancellationToken;
            retryCount?: number;
            account?: Account;
            onStartHttpRequest?: () => void;
        } = {}
    ): AsyncResult<KustoQueryResult, KustoClientErrorDescription[]> {
        const applyAppInsightHack = dynamicEscapeProxyHostnames.includes(new URL(this.url).hostname)
            ? {
                  // Should only occur if the application insights fix isn't required anymore
                  onAppInsightsFixed: () => {
                      this.telemetry.exception('Application insights endpoint fixed?');
                  },
              }
            : undefined;

        const cancelQuery = () => {
            this.cancelQuery(t);
        };
        signal?.addEventListener('abort', cancelQuery);

        let res: ExecutionResult<'v1'> | ExecutionResult<'v2'>;

        try {
            res = await this.execute_deprecated(dbName, queryOrCommand, isQuery, apiVersion, {
                ...options,
                requestTimeoutMessage,
            });
        } catch (e) {
            if (e instanceof KustoClientError) {
                if (e.data) {
                    return err([
                        kustoOneApiErrorToInfo(
                            e.data as OneApiError,
                            this.telemetry,
                            applyAppInsightHack !== undefined,
                            this.clientRequestId
                        ),
                    ]);
                }
                // Fallback to legacy error handling if there's no request response data
                return err([extractErrorDescription(e)]);
            }
            this.telemetry.exception('Unknown kusto client error', { exception: e });
            return err([{ errorMessage: t.utils.util.error.unidentifiedErrorMessage }]);
        } finally {
            signal?.removeEventListener('abort', cancelQuery);
        }

        // TODO: Delete this, and return `Aborted` because we canceled the ajax
        // request, and catch the abort error
        if (signal?.aborted) {
            return ABORTED;
        }

        // NOTE: Cluster configuration can override requested format, causing
        // response to be in v1 format or v2 format regardless of what we asked
        // for.
        return isV1Response(res.apiCallResult)
            ? normalizeResultV1(res.apiCallResult, this.telemetry, applyAppInsightHack)
            : normalizeResultV2(res.apiCallResult, this.telemetry, applyAppInsightHack);
    }

    /**
     * Execute a query or a command query against a Kusto cluster
     *
     * @param dbName The name of the database
     * @param command The query or command to run. (commands starts with `.`, like `.show schema`)
     * @param t The locale to use for error messages
     * @param options Provides client request properties that modify how the request is processed and its results.
     * @param options.properties Provides client request properties that modify
     * how the request is processed and its results. For more information, see
     * client request properties. @see
     * https://docs.microsoft.com/en-us/azure/data-explorer/kusto/api/netfx/request-properties#clientrequestproperties-options.
     * @param options.signal When AbortSignal is fired, request is canceled
     * @param options.requestTimeout Timeout before the request is cancelled.
     * @param options.cancelToken Cancellation token created with
     * createCancelToken.
     * @param options.retryCount On auth failure how many retries to perform.
     * Default is one.
     * @param options.account The account to use to authorize the request. If
     * account is not passed, use the default account in the authentication
     * provider (the logged-in user).
     * @param options.onStartHttpRequest a callback that is triggered after a
     * token is fetched but before the request has been sent.
     */
    executeControlCommand(
        dbName: string | undefined,
        command: string,
        t: KustoClientLocale,
        options?: {
            signal?: AbortSignal;
            account?: Account;
            properties?: ClientRequestProperties;
            requestTimeout?: number;
            requestHeaders?: RequestHeaders;
            cancelToken?: CancelToken;
        }
    ): AsyncResult<KustoQueryResult, KustoClientErrorDescription[]> {
        return this.execute(dbName, command, false, 'v1', t, options);
    }

    /**
     * Execute a query or a command query against a Kusto cluster
     * @param dbName The name of the database
     * @param queryOrCommand  The query or command to run. (commands starts with `.`, like `.show schema`)
     * @param query Is it a query or command
     * @param t The locale to use for error messages
     * @param apiVersion Which API Version to use. Currently command queries are only supported by v1.
     * @param options.properties Provides client request properties that modify how the request is processed and its results. For more information, see client request properties. @see https://docs.microsoft.com/en-us/azure/data-explorer/kusto/api/netfx/request-properties#clientrequestproperties-options.
     * @param options.signal When AbortSignal is fired, request is canceled
     * @param options.requestTimeout Timeout before the request is cancelled.
     * @param options.cancelToken Cancellation token created with createCancelToken.
     * @param options.retryCount On auth failure how many retries to perform. Default is one.
     * @param options.account The account to use to authorize the request. If account is not passed, use the default account in the authentication provider (the logged in user).
     * @param options.onStartHttpRequest a callback that is triggered after a token is fetched but before the request has been sent.
     */
    executeQuery(
        dbName: string,
        query: string,
        t: KustoClientLocale,
        {
            apiVersion = 'v2',
            ...options
        }: {
            signal?: AbortSignal;
            properties?: ClientRequestProperties;
            requestHeaders?: RequestHeaders;
            cancelToken?: CancelToken;
            apiVersion?: 'v1' | 'v2';
        } = {}
    ): AsyncResult<KustoQueryResult, KustoClientErrorDescription[]> {
        return this.execute(dbName, query, true, apiVersion, t, options);
    }

    async cancelQuery(
        t: KustoClientLocale,
        options?: {
            signal: AbortSignal;
            requestTimeout?: number;
        }
    ): AsyncResult<KustoQueryResult, KustoClientErrorDescription[]> {
        return this.executeControlCommand(undefined, `.cancel query '${this.clientRequestId}'`, t, options);
    }

    createCancelToken(): CancellationTokenSource {
        return axios.CancelToken.source();
    }
}
