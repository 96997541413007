import React from 'react';
import {
    HeadlessFlatTreeItemProps,
    HeadlessFlatTreeOptions,
    TreeItemProps,
    TreeOpenChangeData,
    TreeProps,
} from '@fluentui/react-components';

export type ActionMenuItemType = (treeEntity: TreeNode, isButton?: boolean) => JSX.Element;

export interface EntityActionsMapType {
    [entityType: string]: { menuItems: ActionMenuItemType[]; asideItems?: ActionMenuItemType[] };
}

interface TreeCommonProps {
    /** Map of entity type to icon */
    icons?: {
        [key: string]: JSX.Element | null;
    };
    /** Map of entity type to entity component */
    entityMap?: {
        [key: string]: React.FC<TreeItemEntityProps>;
    };
    /** Map of entity type to actions */
    entityActionsMap?: EntityActionsMapType;

    /** Set of selected entities. */
    selectedItems?: Iterable<string> | undefined;
    /** Id of the entity to scroll into view. */
    scrollIntoViewItem?: string | undefined;
}

export type TreeNode = FlatTreeEntity | NestedTreeEntity;
export interface FlatTreeEntity
    extends Omit<NestedTreeEntity, 'subtree' | 'name'>,
        Omit<HeadlessFlatTreeItemProps, 'id'> {
    value: string;
    parentValue?: string;
    content: string;
}

export interface HeadlessFlatTreeProps extends HeadlessFlatTreeOptions, TreeCommonProps {
    /** List of entities to render */
    list: FlatTreeEntity[];
    /** String to filter and highlight tree by */
    searchString?: string;
    /** Callback when an entity is clicked */
    onEntityClick?: (entity: FlatTreeEntity, data: TreeOpenChangeData) => void;
    /** Callback when ErrorBoundary catch error and renders the fallback */
    logErrorToService?: (error: Error, componentStack: string) => void;

    /** Focus indicator on click: Optional fields indicating the value of the item in focus */
    itemInFocus?: string;
    /** Focus indicator on click: Optional callback to set the value of item in focus */
    setItemInFocus?: (item: string) => void;
    /** Style to apply to the highlighted text when search results appear */
    highlightStyle?: React.CSSProperties;
}

export interface ConnectionTreeProps extends TreeProps, TreeCommonProps {
    /** List of entities to render */
    list: NestedTreeEntity[];
    /** String to filter and highlight tree by */
    searchString?: string;
    /** Callback when an entity is clicked */
    onEntityClick?: (entity: NestedTreeEntity, data: TreeOpenChangeData) => void;
    /** Callback when ErrorBoundary catch error and renders the fallback */
    logErrorToService?: (error: Error, componentStack: string) => void;
}

export type TreeEntityStatus = 'Initial' | 'Valid' | 'Error' | 'Validating' | 'Loading' | 'Dirty' | 'FetchNotStarted';
export const statusRequiresSpinner = ['Validating', 'Loading'];

export interface NestedTreeEntity {
    id: string;
    name: string;
    entityType: string;
    onOpen?: (isOpen?: boolean) => void;
    status: TreeEntityStatus;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    root?: any;
    error?: string | undefined;
    itemType?: 'branch' | 'leaf' | undefined;
    subtree: NestedTreeEntity[];
    docstring?: string;
    hasDivider?: boolean;
    description?: string;
    notSearchable?: boolean;
    treeItemStyle?: React.CSSProperties;
    treeItemLabelStyle?: React.CSSProperties;
}

export interface TreeItemEntityProps extends Partial<Pick<TreeItemProps, 'itemType'>>, TreeCommonProps {
    entity: NestedTreeEntity;
    highlightString?: string;
}
