@use '@kusto/style/tokens';
.jpViewLabel {
    font-weight: bold;
    color: tokens.$colorNeutralForeground1;
}

.jpViewValue {
    display: flex;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.jpViewSeparator {
    margin: 0 2px;
}

.colorFragment {
    color: var(--colorPaletteBerryForeground3);
}
