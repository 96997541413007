import React, { useEffect, useRef, useState } from 'react';
import { Divider, Popover, PopoverSurface, PopoverTrigger, PositioningImperativeRef } from '@fluentui/react-components';

import { useStrings } from '../../../hooks/useStrings';
import { getUserPillIcon } from '../../../kqlParser/pillToIcons';
import { pillToKql } from '../../../kqlParser/pillToKql';
import { getPillDescription, getPillText } from '../../../kqlParser/pillToText';
import { useDataExplorationStore } from '../../../store/DataExplorationStore';
import { Pill } from '../../../types/Pills.interfaces';
import { AggregationPillPopover } from '../Popovers/AggregationPillPopover';
import { FilterPillPopover } from '../Popovers/FilterPillPopover';
import { TagPill } from './TagPill';

import styles from './../PillsBar.module.scss';

function getPillPopover(pill: Pill, onApply: (newState: Pill) => void, onCancel: () => void): string | JSX.Element {
    switch (pill.type) {
        case 'filter':
            return <FilterPillPopover defaultValues={pill.data} onApply={onApply} onCancel={onCancel} />;

        case 'aggregation':
            return <AggregationPillPopover defaultValues={pill.data} onApply={onApply} onCancel={onCancel} />;

        case 'datetime':
            throw new Error('PillPopover: DataTime pill is not supported yet');

        case 'limit':
            throw new Error('PillPopover: Limit pill is not supported yet');
    }
}

export interface UserPillProps {
    pill: Pill;
    pillIndex: number;
    /** If readonly, then the pill can't be edited or removed */
    readonly: boolean;
}

const UserPill: React.FunctionComponent<UserPillProps> = ({ pill, pillIndex, readonly }) => {
    const { strings } = useStrings();
    const { actions } = useDataExplorationStore();

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const onApply = (newState: Pill) => {
        actions.updatePill(newState, pillIndex);
        setIsPopoverOpen(false);
    };

    const onCancel = () => {
        setIsPopoverOpen(false);
    };

    const pillText = getPillText(pill, strings);
    const pillDescription = getPillDescription(pill, strings);
    const pillKql = pillToKql(pill);
    const pillIcon = getUserPillIcon(pill);
    const popoverContent = getPillPopover(pill, onApply, onCancel);

    // Position the popover relevant to the TagPill position
    const buttonRef = useRef<HTMLButtonElement>(null);
    const positioningRef = useRef<PositioningImperativeRef>(null);

    useEffect(() => {
        if (buttonRef.current) {
            positioningRef.current?.setTarget(buttonRef.current);
        }
    }, [buttonRef, positioningRef]);

    return (
        <Popover
            open={isPopoverOpen}
            onOpenChange={(e, { open }) => setIsPopoverOpen(open)} // auto close on click outside
            positioning={{ positioningRef }}
            withArrow
        >
            <PopoverTrigger disableButtonEnhancement>
                <TagPill
                    text={pillText}
                    kqlDescription={pillDescription}
                    kqlText={pillKql}
                    icon={pillIcon}
                    readonly={readonly}
                    onClick={() => setIsPopoverOpen(true)}
                    overflow={{ id: `user-pill-${pillIndex}` }}
                    ref={buttonRef}
                />
            </PopoverTrigger>
            <PopoverSurface tabIndex={-1}>{popoverContent}</PopoverSurface>
        </Popover>
    );
};

/** User pills - The user can create, edit or delete them. */
export const UserPills: React.FunctionComponent = () => {
    const { pills } = useDataExplorationStore().state;

    if (!pills.length) {
        return null;
    }

    return (
        <>
            <Divider vertical className={styles.divider} />
            {pills.map((pill, i) => (
                <UserPill pill={pill} pillIndex={i} readonly={i !== pills.length - 1} key={i} />
            ))}
        </>
    );
};
