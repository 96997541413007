import React from 'react';
import { Body1, Body1Strong } from '@fluentui/react-components';

import { useStrings } from '../../../../hooks/useStrings';

export const FieldLabel: React.FunctionComponent<{ text: string; optional?: boolean }> = ({ text, optional }) => {
    const { strings } = useStrings();

    return (
        <>
            <Body1Strong>{text}</Body1Strong>
            {optional && <Body1 italic> ({strings.dataExplorationModal.pillsBar.optional})</Body1>}
        </>
    );
};
