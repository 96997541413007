/** @jsxRuntime automatic */
/** @jsxImportSource @fluentui/react-jsx-runtime */
import * as React from 'react';
import {
    assertSlots,
    FlatTreeProps,
    FlatTreeSlots,
    ForwardRefComponent,
    HeadlessFlatTreeItem,
    TreeNavigationData_unstable,
    TreeNavigationEvent_unstable,
    TreeOpenChangeData,
    TreeOpenChangeEvent,
    TreeProvider,
    useFlatTree_unstable,
    useFlatTreeContextValues_unstable,
    useFlatTreeStyles_unstable,
    useHeadlessFlatTree_unstable,
} from '@fluentui/react-components';
import { FixedSizeList, FixedSizeListProps, ListChildComponentProps } from 'react-window';

import { FlatTreeItemComponent } from './ConnectionFlatTree';
import { EntityActionsMapType, FlatTreeEntity, HeadlessFlatTreeProps } from './types';

type FixedSizeTreeProps = Omit<FlatTreeProps, 'children'> & {
    listProps: FixedSizeListProps & { ref?: React.Ref<FixedSizeList> };
};

/**
 * FixedSizeTree is a recomposition of Tree component that uses react-window FixedSizeList to render items.
 */
const FixedSizeTree: ForwardRefComponent<FixedSizeTreeProps> = React.forwardRef((props, ref) => {
    const state = useFlatTree_unstable(props, ref);
    useFlatTreeStyles_unstable(state);
    const contextValues = useFlatTreeContextValues_unstable(state);
    assertSlots<FlatTreeSlots>(state);
    const handleOuterRef = React.useCallback((instance: HTMLElement | null) => {
        if (instance) {
            // This element stays between the tree and treeitem
            // Due to accessibility issues this element should have role="none"
            instance.setAttribute('role', 'none');
        }
    }, []);
    return (
        <TreeProvider value={contextValues.tree}>
            <state.root>
                <FixedSizeList outerRef={handleOuterRef} {...props.listProps} />
            </state.root>
        </TreeProvider>
    );
});

interface FixedSizeTreeItemProps extends ListChildComponentProps {
    data: {
        items: HeadlessFlatTreeItem<FlatTreeEntity>[];
        icons: { [key: string]: JSX.Element };
        selectedItems?: Iterable<string> | undefined;
        entityActionsMap?: EntityActionsMapType;
        searchString?: string;
        itemInFocus?: string;
        setItemInFocus?: (item: string) => void;
    };
}

const FixedSizeTreeItem = (props: FixedSizeTreeItemProps) => {
    const { style } = props;
    const { items } = props.data;
    const flatTreeItem = items[props.index];
    return (
        <React.Fragment key={`${flatTreeItem.position} , ${flatTreeItem.value}`}>
            <FlatTreeItemComponent flatTreeItem={flatTreeItem} style={style} {...props.data} />
        </React.Fragment>
    );
};

export const Virtualization: React.FC<HeadlessFlatTreeProps & { width?: string; height?: string }> = ({
    list,
    icons,
    entityActionsMap,
    defaultOpenItems,
    scrollIntoViewItem,
    openItems,
    onEntityClick,
    searchString,
    selectedItems,
    itemInFocus,
    setItemInFocus,
    highlightStyle,
    height = '300',
    width = '300',
}) => {
    const handleOpenChange = (_: TreeOpenChangeEvent, data: TreeOpenChangeData) => {
        const entity = headlessTree.getItem(data.value)?.getTreeItemProps();
        if (entity) {
            onEntityClick?.(entity, data);
        }
    };

    const headlessTree = useHeadlessFlatTree_unstable(list, {
        defaultOpenItems,
        openItems: React.useMemo(() => (openItems ? Array.from(openItems) : undefined), [openItems]),
        onOpenChange: handleOpenChange,
    });
    const listRef = React.useRef<FixedSizeList>(null);

    // This line is crucial for everything to work.
    const items = React.useMemo(() => Array.from(headlessTree.items()), [headlessTree]);

    /**
     * Since navigation is not possible due to the fact that not all items are rendered,
     * we need to scroll to the next item and then invoke navigation.
     */
    const handleNavigation = (event: TreeNavigationEvent_unstable, data: TreeNavigationData_unstable) => {
        const nextItem = headlessTree.getNextNavigableItem(items, data);
        if (!nextItem) {
            return;
        }
        // if the next item is not rendered, scroll to it and try to navigate again
        if (!headlessTree.getElementFromItem(nextItem)) {
            event.preventDefault(); // preventing default disables internal navigation.
            listRef.current?.scrollToItem(nextItem.index);
            // waiting for the next animation frame to allow the list to be scrolled
            return requestAnimationFrame(() => headlessTree.navigate(data));
        }
    };

    const itemData = React.useMemo(
        () => ({
            items,
            icons,
            entityActionsMap,
            selectedItems,
            searchString,
            itemInFocus,
            setItemInFocus,
            highlightStyle,
        }),
        [items, icons, entityActionsMap, selectedItems, searchString, itemInFocus, setItemInFocus, highlightStyle]
    );

    React.useEffect(() => {
        if (!scrollIntoViewItem) {
            return;
        }
        const item = headlessTree.getItem(scrollIntoViewItem);
        if (item) {
            const element = headlessTree.getElementFromItem(item);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                element.focus();
            } else {
                listRef.current?.scrollToItem(item.index);
                requestAnimationFrame(() => {
                    const element = headlessTree.getElementFromItem(item);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                        element.focus();
                    }
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollIntoViewItem]);

    return (
        <FixedSizeTree
            {...headlessTree.getTreeProps()}
            listProps={{
                ref: listRef,
                itemCount: items.length,
                itemData,
                itemSize: 36,
                height: parseInt(height, 10),
                width: parseInt(width, 10),
                children: FixedSizeTreeItem,
            }}
            onNavigation={handleNavigation}
            aria-label="Virtualized connection tree"
        />
    );
};
