import React from 'react';

import type { FeatureFlags } from '@kusto/app-common';
import { KweRtdVisualContext, RtdProviderLocale, rtdVisualsConfig } from '@kusto/rtd-provider';
import type { IKweTelemetry } from '@kusto/utils';
import { ParsedVisuals, parseVisuals, VisualFwkFeatureFlag } from '@kusto/visual-fwk';
import type { KustoDataProps } from '@kusto/visualizations';

import { staticChartEvents } from '../components/charting/KweChart';
import { QueryResultGrid } from '../components/QueryResults/QueryResultGrid';
import { QueryCompletionColumns, QueryCompletionRows } from '../stores/queryCompletionInfo';
import { IRootStore } from '../stores/rootStore';

function TableComponent(props: KustoDataProps) {
    const baseResultToDisplay = props.resultToDisplay;
    const resultToDisplay = React.useMemo(
        () =>
            baseResultToDisplay
                ? {
                      tableName: null,
                      rows: baseResultToDisplay.rows as QueryCompletionRows,
                      columns: baseResultToDisplay.columns as QueryCompletionColumns,
                      visualizationOptions: baseResultToDisplay.visualizationOptions,
                      isChart: false,
                      queryIndex: 0,
                      columnsHash: 0,
                  }
                : undefined,
        [baseResultToDisplay]
    );
    return resultToDisplay ? <QueryResultGrid {...props} resultToDisplay={resultToDisplay} disableExpandTypes /> : null;
}

export function createParsedVisuals(
    store: IRootStore,
    strings: RtdProviderLocale,
    telemetry: IKweTelemetry,
    azureMapSubscriptionKey: undefined | string,
    publicRtdPlotlyUrl: KweRtdVisualContext['publicRtdPlotlyUrl'],
    securePlotlyIframe: KweRtdVisualContext['securePlotlyIframe'],
    flags: FeatureFlags<VisualFwkFeatureFlag>
): ParsedVisuals {
    const config: KweRtdVisualContext = {
        strings,
        azureMapSubscriptionKey,
        get chartProps() {
            return {
                showAllSeriesOnHover: store.settings.showAllSeriesOnHover,
                useBoost: store.settings.useBoost,
                ignoreChartLimits: store.settings.ignoreChartLimits,
            };
        },
        chartEvents: {
            ...staticChartEvents,
            disableChartLimits: () => store.settings.setIgnoreChartLimits(true),
        },
        telemetry,
        publicRtdPlotlyUrl: publicRtdPlotlyUrl,
        securePlotlyIframe,
        renderTable: (args) => <TableComponent {...args} />,
        get originAllowlist() {
            return store.settings.originAllowlist;
        },
        flags,
    };
    const visualConfig = rtdVisualsConfig(config);

    return parseVisuals(visualConfig, telemetry);
}
