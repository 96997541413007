// TODO duplicated in monaco-kusto . export it there and consume it here.
import { getDomainPrefixedByProtocol, KweException } from '@kusto/utils';

/**
 * This method is not idempotent.
 * escape(escape('some.domain.com')) will return some\\\\.domain\\\\.com
 * and not: some\\.domain\\.com
 */
const escape = (str: string): string => {
    return str.replace(/\./g, '\\.');
};

export interface KustoDomainsConfig {
    readonly synapsePoolPostfix: string;
    readonly defaultDomainPostfix: string;
    readonly subDomains?: readonly string[];
    readonly additionalDomainRegex?: RegExp;
}

export class KustoDomains {
    constructor(public readonly config: KustoDomainsConfig) {}

    public LocalDevDomain = 'localhost';
    public KustoSubDomain = 'kusto';
    private AriaCluster = 'kusto.aria.microsoft.com';
    private allowedDomainRegexes: RegExp[] = [];

    public get DefaultDomainPostfix() {
        if (!this.config.defaultDomainPostfix) {
            throw new KweException('DefaultDomainPostfix is not initialized');
        }
        return this.config.defaultDomainPostfix;
    }

    public doesDomainSupportMaterializedViews(clusterURL: string) {
        const domain = new URL(clusterURL).host;
        const isAppInsightsDomain = this.isAppInsightsDomain(domain);
        const isPlayFabSubDomain = this.isPlayFabSubDomain(domain);

        return !(isAppInsightsDomain || isPlayFabSubDomain);
    }

    public doesDomainSupportLiteSchemaExploration(clusterURL: string) {
        const domain = new URL(clusterURL).host;
        const isAppInsightsDomain = this.isAppInsightsDomain(domain);
        const isAriaDomain = this.isAriaDomain(domain);
        const isPlayFabSubDomain = this.isPlayFabSubDomain(domain);

        return !(isAppInsightsDomain || isAriaDomain || isPlayFabSubDomain);
    }

    private createKustoDefaultDomain(domainPostFix: string) {
        const parts = ['', this.KustoSubDomain, domainPostFix];
        return parts.join('.');
    }

    public get Default() {
        return this.createKustoDefaultDomain(this.DefaultDomainPostfix);
    }

    public get SynapseDefault() {
        return this.createKustoDefaultDomain(this.config.synapsePoolPostfix);
    }

    public get TridentDefault() {
        return this.createKustoDefaultDomain(this.KustoTridentDomain);
    }

    // TODO: once trident will migrate old clusters remove this
    public get TridentOldDefault() {
        return this.createKustoDefaultDomain(this.KustoTridentOldDomain);
    }

    public setDomains(allowedDomains: string[]): void {
        const defaultAllowedDomains = this.config.subDomains ?? [];
        this.allowedDomainRegexes = [...defaultAllowedDomains, ...allowedDomains].map(
            (domain) => new RegExp(escape(domain) + '$', 'i')
        );
        if (this.config.additionalDomainRegex) {
            this.allowedDomainRegexes.push(this.config.additionalDomainRegex);
        }
    }

    /**
     * @returns true if this domain with/without protocol belongs to a known kusto sub-domain or to a trusted host.
     * @param url domain url object
     */
    public isUrlKustoSubDomain(url: URL): boolean {
        return this.isKustoSubDomain(url.host) || this.isKustoSubDomain(getDomainPrefixedByProtocol(url));
    }

    /**
     * @returns true if this domain belongs to a known kusto sub-domain or to a trusted host.
     * @param domain string url
     */
    public isKustoSubDomain(domain: string) {
        return (
            this.AriaClusterRegex.test(domain) ||
            this.PlayFabSubDomainRegex.test(domain) ||
            this.AppInsightsSubDomainRegex.test(domain) ||
            this.LogAnalyticsSubDomainRegex.test(domain) ||
            this.ADXProxySubDomainRegex.test(domain) ||
            this.IdentityDiagnosticsPPESubDomainRegex.test(domain) ||
            this.IdentityDiagnosticsSubDomainRegex.test(domain) ||
            this.SubDomainRegex.test(domain) ||
            this.LocalDevDomainRegex.test(domain) ||
            this.synapseDevDomainRegex.test(domain) ||
            this.kustoDevDomainRegex.test(domain) ||
            this.LinkedInSubDomainRegex.test(domain) ||
            this.allowedDomainRegexes.some((regex) => regex.test(domain))
        );
    }

    /**
     * @returns true if parameters are NOT supported.
     * @param domain the url
     */
    public isParametersNotSupported(domain: string) {
        return this.proxiesWithoutParameterSupport.some((regex) => regex.test(domain));
    }

    public isAppInsightsDomain(domain: string) {
        return this.AppInsightsSubDomainRegex.test(domain) || this.LogAnalyticsSubDomainRegex.test(domain);
    }

    public isPlayFabSubDomain(domain: string) {
        return this.PlayFabSubDomainRegex.test(domain);
    }

    public supportKustoAADScopeDomain(domain: string) {
        // Used for Trident.
        // By default Trident uses cluster scope. But, for this clusters list, Trident should NOT use cluster scope.
        return (
            this.LogAnalyticsSubDomainRegex.test(domain) ||
            this.AppInsightsSubDomainRegex.test(domain) ||
            this.ADXProxySubDomainRegex.test(domain) ||
            this.LinkedInSubDomainRegex.test(domain)
        );
    }

    public getClusterName(domain: string) {
        return this.isAriaDomain(domain)
            ? domain.replace('https://', '').replace('microsoft.com/', 'microsoft.com')
            : domain.replace(this.SubDomainRegex, (_full, _protocol, alias) => alias);
    }

    public isAriaDomain(domain: string) {
        return this.AriaClusterRegex.test(domain);
    }

    public isLocalDevDomain(domain: string) {
        return this.LocalDevDomainRegex.test(domain);
    }

    private readonly AriaClusterRegex = new RegExp('/?' + escape(this.AriaCluster) + '/?$', 'i');
    private readonly PlayFabSubDomainRegex = new RegExp('(.+)\\.playfab(api)?\\.com$', 'i');
    private readonly AppInsightsSubDomainRegex = new RegExp(
        'ade\\.applicationinsights\\.io$|adx\\.applicationinsights\\.azure\\.com$',
        'i'
    );

    private readonly LogAnalyticsSubDomainRegex = new RegExp('ade\\.loganalytics\\.io$', 'i');
    private readonly ADXProxySubDomainRegex = new RegExp(
        'adx\\.(monitor|loganalytics|applicationinsights)\\.azure\\.(com|us|cn|microsoft\\.scloud|eaglex\\.ic\\.gov)$',
        'i'
    );

    private readonly IdentityDiagnosticsPPESubDomainRegex = new RegExp('dxp-dev\\.aad\\.azure\\.com$', 'i');
    private readonly IdentityDiagnosticsSubDomainRegex = new RegExp('dxp\\.aad\\.azure\\.com$', 'i'); // Used by AAD Support Engineers.
    private readonly LocalDevDomainRegex = new RegExp('/?' + this.LocalDevDomain + '/?$', 'i');
    private readonly synapseDevDomainRegex = new RegExp('kustodev\\.azuresynapse-dogfood\\.net$', 'i');
    private readonly kustoDevDomainRegex = new RegExp('kustodev\\.windows\\.net$', 'i');
    private readonly LinkedInSubDomainRegex = new RegExp('\\.linkedin\\.com$', 'i');

    // fabric.microsoft.com or data.microsoft.com
    // TODO: once trident will go mutliclud move to env.ts
    private readonly KustoTridentDomain = 'fabric.microsoft.com';
    // TODO: once trident will migrate old clusters remove this
    private readonly KustoTridentOldDomain = 'data.microsoft.com';

    private get SubDomainRegex() {
        return new RegExp(
            '(https?://)?(.+)\\.' + this.KustoSubDomain + '(mfa)?\\.' + this.getSubDomainPostifxesRegex() + '/?$',
            'i'
        );
    }

    private getSubDomainPostifxesRegex() {
        const postfixes = [
            this.DefaultDomainPostfix,
            this.config.synapsePoolPostfix,
            this.KustoTridentDomain,
            this.KustoTridentOldDomain,
        ].map((postfix) => escape(postfix));
        return `(${postfixes.join('|')})`;
    }

    private readonly proxiesWithoutParameterSupport = [
        this.PlayFabSubDomainRegex,
        this.AppInsightsSubDomainRegex,
        this.LogAnalyticsSubDomainRegex,
        this.ADXProxySubDomainRegex,
        this.IdentityDiagnosticsPPESubDomainRegex,
        this.IdentityDiagnosticsSubDomainRegex,
        this.synapseDevDomainRegex,
        this.kustoDevDomainRegex,
    ];
}
