import { isDiskVersionAtLeast } from '@kusto/dashboard';

import type { FeatureFlagMapAll } from '../../core';
import { KWE_CONSTANTS } from '../constants';

export const iFrameFeatureFlags: FeatureFlagMapAll = {
    ShowConnectionButtons: true,
    EnableAddTable: false, // diff from kweSiteFeatureFlags
    EnableGetData: false, // diff from kweSiteFeatureFlags
    EnableDataProfile: false, // diff from kweSiteFeatureFlags
    DataProfilePinned: false, // diff from kweSiteFeatureFlags
    GridWithExpandRewrite: true,
    ShowShareMenu: true,
    ShowFileMenu: true,
    ShowPersona: true,
    IFrameAuth: true, // diff from kweSiteFeatureFlags
    ShowOpenNewWindowButton: false,
    ShowSmoothIngestion: true,
    ShowPageHeader: true,
    UseMeControl: true,
    EnableDashboards: true,
    QueryResultsSearch: true,
    SyncBrowserTabs: true,
    EnableEventHubSource: true,
    HighlightUrlColumns: true,
    EnableCreateExternalTable: true,
    Timezone: true,
    HideDashboardsOnly: false,
    BrowseSource: true,
    UseIFrameSharing: false,
    UseLocalStorageForTokens: true,
    statsv2: true,
    AddHelpClusterOnEmptyState: true,
    consoleLogs: false,
    verboseLogs: false,
    EnableNPS: false,
    enableTablePolicy: true,
    HideTabs: false,
    EnableQueryParameterLogging: false,
    VirtualCluster: false,
    // TODO: Cleanup the specific FF once the new VC is fully rolled out
    VirtualClusterUpgrade: false,
    VirtualClusterEventHub: false,
    VirtualClusterFastProvisioning: false,
    // TODO: Cleanup the specific FF once the new VC is fully rolled out
    AddSuggestNewFeatureLink: true,
    Homepage: true,
    queryRecommendations: true,
    cheackClusterHealth: false,
    ShowNavigation: true,
    WhatIsNew: true,
    ShowItalyAccessibilityDeclarations: true,
    DisableTelemetryPiiRedactor: true,
    EnableNoLoginVirtualCluster: true,
    EnableMarkdownImageOverflow: false,
    EnableInteractiveLegend: true,
    EnableSharedCrosshair: true,
    DisableFeedback: false,
    FindMyPartner: true,
    QueryVisualOptions: true,
    ClusterAccount: true,
    AddVisual: true,
    HomepagePromotionBanner: true,
    SecurePlotlyIframe: false,
    EnableRoamingProfile: false,
    KeepOnlyOpenInExcelOption: false,
    DashboardsControlCommandParameters: false,
    EnableCreateTableWizard: false,
    EnableDashboardsDevMenu: false,
    HideConnectionPane: false,
    NewTabs: true,
    PersistAfterEachRun: false,
    RefreshConnection: false,
    SkipMonacoFocusOnInit: false,
    TridnetView: false,
    ducks: false,
    enableLanguageHotReloading: false,
    ibizaPortal: false,
    ConnectionPaneUsingFluentV9: false,
    EnableConnectionPaneGroups: false,
    GridDebugMode: false, // Enable only with query params
    ppe: false,
    canary: false,
    TenantAdminSettings: false,
    EnableExploreData: false,
    enableSelectedColumnInfo: true,
    enableAuthMetadataScopes: false,

    /// Dashboards
    DisableDashboardTopBar: false,
    DisableNewDashboard: false,
    DisableDashboardSearch: false,
    DisableDashboardEditMenu: false,
    DisableDashboardFileMenu: false,
    DisableDashboardShareMenu: false,
    DisableDashboardDelete: false,
    DisableTileRefresh: false,
    DisableDashboardAutoRefresh: false,
    DisableExploreQuery: false,
    DisableCrossFiltering: false,
    HideDashboardParametersBar: false,
    debugVisualConfig: false,
    baseQueryCascading: false,
    queryBatching: false,
    exportAsVersion: false,
    newRtdShareMenu: false,
    baseQueriesAsDataSource: false,
    'scope--json': false,
    'scope--csv': false,
    'enable-msa-sharing': false,
    OutageGeoMigrationWarning: false,
    'dashboards--experimental-system-variables': false,

    /// KweVisual
    'cross-filter--series--point': false,
    'show-anomaly-columns-visual-option': isDiskVersionAtLeast(KWE_CONSTANTS.diskDashboardVersion, '53'),
};
