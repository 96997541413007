import React from 'react';
import { Label, TooltipHost } from '@fluentui/react';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';

import { DropdownWithSearch, DropdownWithSearchOption, SelectedOption } from '@kusto/ui-components';
import type { FieldSchema } from '@kusto/utils';

import type { KweVisualFwkLocale } from '../../types';
import { SchemaState, VisualInput, VisualInputSelector } from '../../visualConfig/input';
import { VisualOptionKey, VisualOptionsKeysFor } from '../../visualOptions';
import { dividerDropdownOption } from './constants';
import { dropdownWithTypeTextProps } from './dropdownWithTypeText';
import { columnDropdownOption, columnOptionKey, getSchemaDerived, INFER_OPTION_KEY, resolveInferOption } from './utils';

import * as styles from '../styles.module.scss';

export interface SingleColumnDropdownProps {
    t: KweVisualFwkLocale;
    id: string;
    schema: SchemaState;
    selectOptionDisabled?: (column: FieldSchema) => boolean;
    errorMessage?: string;
    className?: string;
    label: string;
    selectedColumn: null | string;
    disabled?: boolean;
    onChange(_: unknown, option?: SelectedOption): void;
    addInferChoice?: boolean;
    /**
     * If passed the infer option will be labeled with this column name and data type.
     */
    inferColumn?: string;
    /**
     * Changes the label of the infer option. Useful if the infer column is not actually an infer
     */
    inferLabel?: string;
}

export const SingleColumnDropdown: React.FC<SingleColumnDropdownProps> = ({
    t,
    id,
    errorMessage,
    className,
    label,
    selectedColumn,
    selectOptionDisabled,
    onChange,
    disabled,
    schema,
    addInferChoice = true,
    inferColumn,
    inferLabel,
}) => {
    const selectedKey = selectedColumn === null ? INFER_OPTION_KEY : columnOptionKey(selectedColumn);

    const { options, disabledReason } = React.useMemo(() => {
        const schemaDerived = getSchemaDerived(t, schema, selectOptionDisabled);
        const inferOption = resolveInferOption(t, inferColumn, schemaDerived.typesByColumn, inferLabel);

        const innerOptions: DropdownWithSearchOption[] = [];
        let shouldAddDivider = false;

        // Infer will always be at the top
        // when we need to add it
        if (addInferChoice) {
            innerOptions.push(inferOption);
            shouldAddDivider = true;
        }

        if (selectedColumn !== null && !schemaDerived.typesByColumn.has(selectedColumn)) {
            innerOptions.push(
                columnDropdownOption(selectedColumn, { kind: 'unavailable' }),
                dividerDropdownOption,
                ...schemaDerived.options
            );
            shouldAddDivider = true;
        }

        if (shouldAddDivider) {
            innerOptions.push(dividerDropdownOption);
        }

        innerOptions.push(...schemaDerived.options);

        return { options: innerOptions, disabledReason: schemaDerived.disabledReason };
    }, [schema, selectOptionDisabled, inferColumn, selectedColumn, t, inferLabel, addInferChoice]);

    return (
        <div className={className}>
            <Label htmlFor={id}>{label}</Label>
            <TooltipHost content={disabledReason ? <>{disabledReason}</> : undefined}>
                <DropdownWithSearch
                    id={id}
                    selectedKeys={selectedKey}
                    options={options}
                    onChange={onChange}
                    disabled={disabled || disabledReason !== undefined}
                    validationMessage={errorMessage}
                    {...dropdownWithTypeTextProps(t)}
                />
            </TooltipHost>
        </div>
    );
};

export function createSingleColumConfigOption<C extends VisualOptionKey, H = unknown>(
    key: VisualOptionsKeysFor<C, null | string>,
    label: string,
    {
        selectInferColumn,
        selectOptionDisabled,
        selectErrorMessage,
    }: {
        selectInferColumn?: VisualInputSelector<C, undefined | string, H>;
        selectOptionDisabled?: (column: FieldSchema) => boolean;
        selectErrorMessage?: VisualInputSelector<C, undefined | string, H>;
    } = {}
): VisualInput<C, H> {
    return {
        id: `column--${key}`,
        keys: [key],
        Component: observer(function EtpVisualOptionsSingleColumConfigOption({ t, model, disabled }) {
            const selectedColumn = model.get(key);

            const { onChange, inferColumn, errorMessage } = React.useMemo(
                () => ({
                    onChange: mobx.action((_: unknown, option?: SelectedOption) => {
                        if (option) {
                            model.set(key, option.data);
                        }
                    }),
                    inferColumn: model.resolveSelector(selectInferColumn),
                    errorMessage: model.resolveSelector(selectErrorMessage),
                }),
                [model]
            );

            return (
                <SingleColumnDropdown
                    t={t}
                    schema={model.getSchema()}
                    selectOptionDisabled={selectOptionDisabled}
                    className={styles.basicInput}
                    label={label}
                    selectedColumn={selectedColumn}
                    id={`visual-options--${key}`}
                    onChange={onChange}
                    disabled={disabled}
                    inferColumn={inferColumn.get()}
                    errorMessage={errorMessage.get()}
                />
            );
        }),
    };
}
