@use '@kusto/style/tokens';

@mixin ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin structure {
    display: flex;
    gap: tokens.$spacingHorizontalS;
    align-items: center;
}

.popoverContainer {
    overflow: hidden;

    div[role='listbox'],
    :global(.fui-Listbox) {
        overflow-y: auto;
        max-height: 300px;
        box-shadow: none;
        top: tokens.$spacingVerticalXS !important;
    }

    :global(.fui-Dropdown__button) {
        min-width: 0;
    }

    :global(.fui-Combobox) {
        margin: tokens.$spacingVerticalXS tokens.$spacingVerticalXS 0;
    }

    :global(.fui-Option)[data-activedescendant] {
        background-color: tokens.$colorNeutralBackground1Selected;

        &::after {
            border: none;
        }
    }

    :global(.fui-Option__checkIcon) {
        min-width: 16px;
    }

    // scroll-bar - webkit Chrome/Edge
    ::-webkit-scrollbar {
        height: 6px;
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: tokens.$colorNeutralForeground4;
        border-radius: 2px;
    }
}

.withoutSearch {
    :global(.fui-Combobox) {
        height: 0;
        border: none;
        margin: 0;

        &:focus-within::after {
            transform: scaleX(0);
        }
    }

    :global(.fui-Combobox__input) {
        caret-color: transparent;
    }

    div[role='listbox'],
    :global(.fui-Listbox) {
        top: 0 !important;
    }
}

.dropdown {
    width: 100%;

    // Selected option title ellipsis
    :global(.fui-Dropdown__button) {
        span,
        div {
            @include ellipsis;
        }
    }
}

// Selected option title structuring
.title {
    display: flex;
    gap: tokens.$spacingHorizontalS;
    align-items: center;
}

.noResults,
.placeholder {
    color: tokens.$colorNeutralForeground3;
}

.horizontalScroll {
    :global(.fui-Option) {
        &[data-activedescendant] {
            min-width: fit-content;
        }

        &:hover {
            min-width: fit-content;
        }
    }
}

.optionEllipsis {
    .optionContent {
        @include ellipsis;
        span {
            @include ellipsis;
        }
    }
}

// Custom content structure - rearrange content to align with the row
.customElem {
    @include structure;
    .optionContent {
        @include structure;
    }
}
