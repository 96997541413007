import { castToError, IKweTelemetry } from '@kusto/utils';

import type { OneApiError } from './KustoClient';
import type { KustoClientErrorDescription } from './kustoRequest';

/**
 * 403 forbidden sometimes returns a better error message in it's \@message
 * property, but it's no JSON.parse-able without trimming the first part.
 * Written to be generic incase there's other error like this we're not yet
 * aware of.
 *
 * Example of the 403 error is in the test code.
 *
 * Maybe the issue is specific to this message and can be removed if we update
 * the backend?
 *
 * For example: "FailedSigningQuery: Forbidden (403-Forbidden): <json>""
 */
export const backendStringResponse = /^\w+ \([\w\d-]+\): (\{.+\})/s;

/**
 * App insights puts json error object insight of error strings
 */
const appInsightsInnerErrorRegex = /\(inner error: (\{.+\})\)$/s;

/**
 * This tries to extract json from the middle of a bad Trident error message.
 * This is likely unreliable? For example, it breaks if there are any `}` in the
 * text that comes after the json.
 *
 * See ./__tests__/tridentPermissionError.json for an example of what this is for
 */
const tridentPermissionError = /^[^{]+Forbidden \(403-Forbidden\): (\{.+\})[^}]+$/s;

const atMessageRegex = { backendStringResponse, tridentPermissionError };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function tryExtractAppInsightsInnerError(obj: any, telemetry: IKweTelemetry): any {
    if (!obj.message) {
        return undefined;
    }

    const innerError = appInsightsInnerErrorRegex.exec(obj.message);

    if (innerError) {
        try {
            const innerObj = JSON.parse(innerError[1]);
            // Log successful parsing so we can tell when this code can be removed
            telemetry.event('App Insights inner error string parsed');
            return innerObj;
        } catch {}
    } else {
        // Some of our old error examples have App insights nesting error
        // information in json strings inside of innererror for some reason.
        // Maybe doesn't exist in the wild anymore?
        try {
            const innerObj = JSON.parse(obj.message).error;
            // Log successful parsing so we can tell when this code can be removed
            telemetry.event('App Insights json property parsed');
            return innerObj;
        } catch {}
    }
}

function getErrorMessage(errorObj: Record<string, string>): undefined | string {
    return (
        errorObj['@errorMessage'] ??
        errorObj['@message'] ??
        // Aria/proxy error messages -
        // {"name":"SyntaxError","message":"Unexpected 'I'","at":1,"text":"Invalid database or tenant."}
        (errorObj.at && errorObj.text) ??
        errorObj.Message ??
        errorObj.message
    );
}

/**
 * Kusto-specific OneApi error parser. Only meant to be used with
 * {@link KustoClientError.response}.data, or errors or in partial successes
 */
export function kustoOneApiErrorToInfo(
    error: OneApiError,
    telemetry: IKweTelemetry,
    appInsights: boolean,
    clientRequestId?: string
): KustoClientErrorDescription {
    try {
        if (!error || !('error' in error)) {
            telemetry.exception('Not an error object');
            return { errorMessage: JSON.stringify(error), clientRequestId };
        }

        // Cast to any because we actually handle more than what's in the
        // `OneApiError` error interface
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let rootObj = error.error as any;

        let errorMessage: undefined | string = getErrorMessage(rootObj);

        while (true) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let candidateObj: any;
            if (rootObj.innererror) {
                candidateObj = rootObj.innererror;
            } else if (appInsights) {
                const _maybeObj = tryExtractAppInsightsInnerError(rootObj, telemetry);
                if (_maybeObj) {
                    candidateObj = _maybeObj;
                } else {
                    break;
                }
            } else if (rootObj['@message']) {
                const atMessage = rootObj['@message'];

                for (const [name, regex] of Object.entries(atMessageRegex)) {
                    const maybeJson = regex.exec(atMessage);
                    if (!maybeJson) {
                        continue;
                    }

                    try {
                        candidateObj = JSON.parse(maybeJson[1]).error;
                    } catch (exception) {
                        telemetry.trace('@message regex failed', { name, exception });
                        break;
                    }

                    // Trace so we can tell when this code can be removed
                    telemetry.trace('json extracted from @message', { name });
                    break;
                }
                if (!candidateObj) {
                    break;
                }
            } else {
                break;
            }
            const candidateMessage = getErrorMessage(candidateObj);

            // If candidate object has a valid error object, update our root
            // object to use it
            if (candidateMessage) {
                rootObj = candidateObj;
                errorMessage = candidateMessage;
            } else {
                telemetry.exception("Root object doesn't have message");
                break;
            }
        }

        if (rootObj.message === undefined && rootObj['@message']) {
            // If this doesn't fire, we can stop reading errors on every object
            // except the final one, and only read these properties
            //
            // If it does fire, we need to either keep reading the additional
            // properties, or keep reading the extra objects. More telemetry
            // will be needed
            telemetry.exception("Root error doesn't have message property");
        }

        if (!errorMessage) {
            telemetry.exception('Failed to extract error message');
            return { errorMessage: JSON.stringify(rootObj), clientRequestId };
        }

        // Newlines are escaped in the string, we unescape them here.
        errorMessage = errorMessage.replace(/\\r/g, '\r').replace(/\\n/g, '\n');

        const line =
            (rootObj['line'] && parseInt(rootObj['line'], 10)) ??
            (rootObj['@line'] && parseInt(rootObj['@line'], 10)) ??
            undefined;

        const pos =
            (rootObj.pos && parseInt(rootObj.pos, 10)) ??
            (rootObj['@pos'] && parseInt(rootObj['@pos'], 10)) ??
            undefined;

        const token = rootObj.token ?? rootObj['@token'];
        const code = rootObj.code;
        const type = rootObj['@type'] || rootObj.type;

        return { errorMessage, line, pos, token, code, type, clientRequestId };
    } catch (e) {
        telemetry.exception('Failed to parse kusto error', { exception: castToError(e) });
        return { errorMessage: JSON.stringify(error), clientRequestId };
    }
}
