/**
 * https://learn.microsoft.com/en-us/azure/data-explorer/kusto/query/schema-entities/entity-names#identifier-quoting
 * > Entity names that are language keywords must be quoted using [' and '] or
 * > using [" and "]
 *
 * Only contains language keywords that force variable names to be escaped.
 *
 * For example, a variable named "project-away" would already have to be escaped
 * because of the '-', so it's not included in this list.
 */
const kustoLanguageKeywords = new Set([
    'where',
    'union',
    'let',
    'union',
    'search',
    'distinct',
    'project',
    'print',
    'extend',
    'sort',
    'top',
    'summarize',
    'count',
    'join',
    'invoke',
]);

/**
 * https://learn.microsoft.com/en-us/azure/data-explorer/kusto/query/schema-entities/entity-names#identifier-naming-rules
 *
 * > Identifiers containing at least one of (spaces, dots, or dashes) do require quoting (see below).
 *
 * > Entity names that are language keywords must be quoted
 */
export function nameRequiresEscape(name: string): boolean {
    return /[ -.]/.test(name) || kustoLanguageKeywords.has(name);
}

export function escapeKustoVariableName(name: string): string {
    return `['${name}']`;
}

/**
 * @param name Must be a valid kusto entity name. See {@link isValidKustoEntityName}
 */
export function escapeNameIfNecessary(name: string): string {
    if (process.env.NODE_ENV !== 'production' && !isValidKustoEntityName(name)) {
        throw new Error('Name must be a valid kusto entity name');
    }

    if (nameRequiresEscape(name)) {
        return escapeKustoVariableName(name);
    }
    return name;
}

/**
 * https://docs.microsoft.com/en-us/azure/kusto/query/schema-entities/entity-names
 */
export function isValidKustoEntityName(value: string): boolean {
    // The Kusto query language reserves all identifiers that start or end with
    // a sequence of two underscore characters (__); users cannot define such
    // names for their own use.
    return (
        !/[^a-zA-Z0-9._\- ]/.test(value) &&
        !value.startsWith('__') &&
        !value.endsWith('__') &&
        value !== '' &&
        value.length <= 1024
    );
}

const replacements: Record<string, string> = {
    '\\': '\\\\',
    "'": "\\'",
    '\n': '\\n',
    '\r': '\\r',
};

function replacer(value: string): string {
    return replacements[value];
}

export function stringToKustoStringExpression(raw: string) {
    // TODO: izikl: from ICM ticket 281847643: error "TypeError: e.replace is not a function at s" caused a crash.
    // Quick Fix: checking that "replace" exists on raw.
    // Follow up required: investigate why raw wasn't a string?
    if (!raw) {
        return `''`;
    }

    if (/'|\n|\r/.test(raw)) {
        return `'${raw.replace(/\\|'|\n|\r/g, replacer)}'`;
    }

    if (raw.includes('\\')) {
        return `@'${raw}'`;
    }

    return `'${raw}'`;
}

/**
 * Useful if we want to generate expression column names.
 *
 * @example datatable
 * ```
 * const dataTable = `datatable(${escapeColumnNameIfNecessary(columnName): string}) ['value']);
 * ```
 *
 * @example extend
 * ```
 * const query = `StormEvents | extend ${escapeColumnNameIfNecessary(columnName): string} = 'value'`;
 * ```
 *
 * TODO: This code isn't very well tested/researched. It's not yet used in
 * production, before it is, we need to invest more in it.
 */
export function escapeColumnNameIfNecessary(value: string): string {
    if (isValidKustoEntityName(value)) {
        return value;
    }
    return `[${stringToKustoStringExpression(value)}]`;
}

/**
 * Convert a kwe table datetime to a kusto format datetime
 *
 * @param value See {@link KweDataTypes.datetime}
 */
export function formatKustoDatetime(value: number) {
    return new Date(value).toISOString();
}

export const sqlQueryRegex = /^\s*(--|select\b|explain\b)/is;
