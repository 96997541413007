import React from 'react';
import { Field, Input } from '@fluentui/react-components';

import { useStrings } from '../../../../hooks/useStrings';
import { FieldLabel } from './FieldLabel';

import styles from '../Popovers.module.scss';

export interface DisplayNameFieldProps {
    hideLabel?: boolean;
    placeholder?: string;
    defaultValue?: string;
    onChange: (value: string) => void;
}

export const DisplayNameField: React.FunctionComponent<DisplayNameFieldProps> = ({
    hideLabel,
    placeholder,
    defaultValue,
    onChange,
}) => {
    const { strings } = useStrings();

    const label = hideLabel ? null : <FieldLabel text={strings.dataExplorationModal.pillsBar.displayName} optional />;

    return (
        <Field label={label}>
            <Input
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={(e, { value }) => onChange(value)}
                className={styles.popoverField}
            />
        </Field>
    );
};
