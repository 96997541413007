import * as H from 'history';

import { KustoDomains } from '@kusto/client';
import { configureSubDomains, QueryResultPersistentStore, QueryStrings } from '@kusto/query';
// TODO: Remove this line when possible
import englishQueryStrings from '@kusto/query/locale/en.json';
import { Account, isValidUUID, Mutable, setupMobxExceptionLogging } from '@kusto/utils';

import { IAuthProvider } from '../AuthenticationProviders/IAuthProvider';
import { IFrameAuthenticationProvider } from '../AuthenticationProviders/IFrameAuthProvider/IFrameAuthenticationProvider';
import { MsalAuthenticationProvider } from '../AuthenticationProviders/MsalAuthProvider/MsalAuthenticationProvider';
import { AppPage, appPages } from '../common/AppPages';
import { KWE_CONSTANTS, KWE_ENV } from '../common/constants';
import { tryCreateRegExp } from '../common/constants/regexes';
import { AppQueryParams, appQueryParams } from '../core/appQueryParams';
import { FeatureFlagMap, InitAppFlagsArgs, initFeatureFlagsService } from '../core/FeatureFlags';
import { BootstrapDependencies, setDependencies } from '../dependencies';
import { AppStrings, englishStrings } from '../locale';
import { LocalStorageKeys } from '../stores/localStorageHelper';
import { createIframeTrustedDomain, IFrameTrustedDomains } from './IFrame/IFrameCommunication';
import { LoginManager } from './Login/loginManager';
import { QueryDeepLinkProperties, queryPageLinkParser } from './queryPageLinkParser';
import { initTelemetryClient } from './telemetry';

function getSessionId(appSearch: AppQueryParams): string {
    const sessionID = appSearch.settings.SessionId;

    if (sessionID && isValidUUID(sessionID)) {
        return sessionID;
    } else {
        return crypto.randomUUID();
    }
}

const noLoginPages: AppPage[] = [
    appPages.HomePage,
    appPages.VirtualCluster,
    appPages.GetStarted,
    appPages.PublicVirtualCluster,
];

function isNoLoginAllow(
    featureFlags: FeatureFlagMap,
    deepLinkProperties: QueryDeepLinkProperties,
    appSearch: AppQueryParams
) {
    if (
        // Basic
        featureFlags.EnableNoLoginVirtualCluster !== true ||
        featureFlags.VirtualCluster !== true ||
        // have environment
        featureFlags.EnableDashboards !== true ||
        featureFlags.ShowNavigation !== true ||
        featureFlags.IFrameAuth === true ||
        // no deep link
        appSearch.settings.tenant ||
        deepLinkProperties.tutorial ||
        deepLinkProperties.query ||
        deepLinkProperties.querySrc ||
        deepLinkProperties.clusterName
    ) {
        return false;
    }

    const availablePathForNoLogin =
        (deepLinkProperties.appPage && noLoginPages.includes(deepLinkProperties.appPage)) ||
        // Make sure the path is empty '/' converted to ['', ''], '/abcd' => ['', 'abcd']
        (deepLinkProperties.pathname?.split('/')?.[1].length ?? 0) < 1;
    return availablePathForNoLogin;
}

type SharedDeps = Pick<
    BootstrapDependencies,
    | 'queryDeepLinkProperties'
    | 'strings'
    | 'queryStrings'
    | 'urlTheme'
    | 'urlLanguage'
    | 'appSearch'
    | 'kustoDomains'
    | 'warnings'
    | 'history'
>;

async function createDependencies(
    isIbizaIFrame: boolean,
    sharedDeps: SharedDeps,
    isIFrame: boolean
): Promise<BootstrapDependencies> {
    const sessionId = getSessionId(sharedDeps.appSearch);
    const featureFlagArgs: Mutable<InitAppFlagsArgs> = {
        isIbizaIFrame,
        isIFrame,
        queryParamFlags: sharedDeps.appSearch.flags,
    };

    const { service: featureFlags, setTenantFlags } = initFeatureFlagsService(featureFlagArgs);
    let authProvider: IAuthProvider | undefined;
    let loginManager: LoginManager | undefined;
    const { appInsights, telemetry } = initTelemetryClient(
        sharedDeps.appSearch,
        sessionId,
        () => authProvider,
        featureFlags.untracked
    );

    // Run as soon as telemetry is available because mobx reaction exceptions
    // that happen before this aren't logged. If we're confident no mobx
    // reactions are created before this, we could move it to avoid pulling mobx
    // into the main chunk
    setupMobxExceptionLogging(telemetry);

    if (featureFlags.untracked.IFrameAuth) {
        authProvider = new IFrameAuthenticationProvider(telemetry);
    } else {
        const aadClientUUID = KWE_ENV.aadClientUUID;
        if (!aadClientUUID) {
            throw Error('KWE_ENV.aadClientUUID is null');
        }
        const redirectUri = `${window.location.protocol}//${window.location.host}`;
        authProvider = await MsalAuthenticationProvider.createPublicClientApplication({
            kustoDomains: sharedDeps.kustoDomains,
            redirectUri,
            clientId: aadClientUUID,
            useLocalStorageForTokens: featureFlags.untracked.UseLocalStorageForTokens,
            allowRedirect: !isNoLoginAllow(
                featureFlags.untracked,
                sharedDeps.queryDeepLinkProperties,
                sharedDeps.appSearch
            ),
            enableAuthMetadataScopes: featureFlags.untracked.enableAuthMetadataScopes,
            telemetry,
        });
        loginManager = new LoginManager(authProvider as MsalAuthenticationProvider);
    }

    const iframeTrustedDomains = featureFlags.untracked.IFrameAuth
        ? createIframeTrustedDomain(sharedDeps.queryDeepLinkProperties.origin)
        : new IFrameTrustedDomains([], []);

    const localStorageKey = isIbizaIFrame
        ? `ibizaRootStore_${sharedDeps.queryDeepLinkProperties.clusterName || ''}`
        : sharedDeps.queryDeepLinkProperties.storeName || 'rootStore';

    const localStorageKeys = new LocalStorageKeys(
        sharedDeps.queryDeepLinkProperties,
        localStorageKey,
        featureFlags.untracked.EnableRoamingProfile,
        isIbizaIFrame ? undefined : authProvider
    );

    const queryResultStore = new QueryResultPersistentStore(localStorageKey);

    const dependencies: Mutable<BootstrapDependencies> = {
        sessionId,
        appInsights,
        telemetry,
        authProvider,
        queryResultStore,
        featureFlags,
        loginManager,
        localStorageKey,
        iframeTrustedDomains,
        localStorageKeys,
        setUserSpecificDependencies: (account: Account) => {
            const { tenantId } = account;
            if (tenantId) {
                setTenantFlags(KWE_CONSTANTS.tenantFeatureFlags[tenantId]);
            }
        },
        ...sharedDeps,
    };

    return dependencies;
}

export async function initBootstrapDependencies(): Promise<BootstrapDependencies> {
    const warnings: Array<(strings: AppStrings) => string> = [];
    const appParams = appQueryParams(window.location.search);

    const additionalDomainRegex = appParams.flags.IFrameAuth
        ? tryCreateRegExp(appParams.settings.allowedDomainRegex, true)
        : undefined;
    const kustoDomains = new KustoDomains({ ...KWE_ENV.domains, additionalDomainRegex });
    configureSubDomains(kustoDomains);

    warnings.push(...appParams.warnings);
    const newUrl = new URL(window.location.href);
    newUrl.search = appParams.newSearch;
    window.history.replaceState(null, '', newUrl);

    const deepLinkParameters = queryPageLinkParser(undefined, kustoDomains, window.location.href);

    if (deepLinkParameters.warnings) {
        warnings.push(...deepLinkParameters.warnings);
    }

    const sharedDeps: SharedDeps = {
        kustoDomains,
        queryDeepLinkProperties: deepLinkParameters,
        strings: englishStrings,
        queryStrings: englishQueryStrings as QueryStrings,
        urlTheme: appParams.settings.theme,
        urlLanguage: appParams.settings.language,
        appSearch: appParams,
        warnings,
        history: H.createBrowserHistory({ basename: KWE_ENV.publicUrl }),
    };

    const IsIbizaIFrame = appParams.flags.ibizaPortal || process.env.NODE_ENV === 'test';
    const dependencies = await createDependencies(IsIbizaIFrame, sharedDeps, !!appParams.flags.IFrameAuth);

    if (appParams.settings.allowedDomainRegex) {
        dependencies.telemetry.trace('allowedDomainRegex', {
            allowedDomainRegex: appParams.settings.allowedDomainRegex,
            isValidRegex: tryCreateRegExp(appParams.settings.allowedDomainRegex, true),
            iframeAuth: appParams.flags.IFrameAuth,
        });
    }

    if (deepLinkParameters.exceptions) {
        for (const exception of deepLinkParameters.exceptions) {
            dependencies.telemetry.exception('App deep link crash', { exception });
        }
    }

    if (dependencies.appSearch.flagsUsedWithoutPrefix.length !== 0) {
        dependencies.telemetry.event('KWE flag set without prefix', {
            flags: dependencies.appSearch.flagsUsedWithoutPrefix,
        });
    }

    setDependencies(dependencies);

    return dependencies;
}
