import { tokens } from '@fluentui/react-components';
import * as Highcharts from 'highcharts';

import { formatLiterals, Locale } from '@kusto/utils';

import { DataExplorationStrings } from '../context/DataExplorationContext';
import { darkTheme } from './darkTheme';
import type { HistogramOkValue } from './types';

/**
 * Using English numeric abbreviations
 * k - thousands
 * M - millions
 * B - billions
 * T - trillions
 */
const NUMERIC_ABBREVIATIONS = ['k', 'M', 'B', 'T'];

export interface CreateHistogramOptionsData {
    data: HistogramOkValue;
    isDarkTheme: boolean;
    timezone: string;
    strings: DataExplorationStrings;
    locale: Locale;
    seriesColor?: Highcharts.ColorString;
    seriesFillColor?: Highcharts.ColorString;
    zoomType?: Highcharts.OptionsZoomTypeValue;
    chartSelectionEvent?: Highcharts.ChartSelectionCallbackFunction;
}

export function createHistogramOptions({
    data,
    isDarkTheme,
    timezone,
    strings,
    locale,
    seriesColor,
    seriesFillColor,
    zoomType,
    chartSelectionEvent,
}: CreateHistogramOptionsData): Highcharts.Options {
    const dateTimeFormatter = new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        fractionalSecondDigits: 3,
        timeZone: timezone,
    });
    const binSize = data[0].binEnd - data[0].binStart;

    // building the actual object now that we converted all the relevant parts.
    const options: Highcharts.Options = {
        title: {
            text: undefined,
        },
        lang: {
            accessibility: {
                defaultChartTitle: strings.histogram.chart.defaultTitle,
            },
            // Using English numeric abbreviations
            // k - thousands
            // M - millions
            // B - billions
            // T - trillions
            // instead of the defaults which relate to
            // kilo, mega, giga, etc.
            numericSymbols: NUMERIC_ABBREVIATIONS,
        },
        chart: {
            // Invalid as a background color, which causes it to be ignored, but
            // also used as svg "fill" property, which we need to be valid.
            backgroundColor: 'none',
            style: {
                fontFamily: tokens.fontFamilyBase,
            },
            spacingLeft: 0,
            spacingRight: 0,
            events: {
                selection: chartSelectionEvent,
            },
            zoomType,
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            type: 'datetime',
            lineWidth: 0,
            minPadding: 0,
            maxPadding: 0,
        },
        yAxis: {
            title: {
                text: undefined,
            },
            visible: false,
        },
        plotOptions: {
            area: {
                fillColor: createFillColor(isDarkTheme, seriesFillColor),
                marker: {
                    enabled: false,
                },
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 1,
                    },
                },
                threshold: null,
            },
        },
        series: createSeriesFromData(data, strings),
        credits: { enabled: false },
        tooltip: {
            enabled: true,
            formatter: function () {
                return (
                    dateTimeFormatter.formatRange(this.x, this.x + binSize) +
                    '<br/>' +
                    formatLiterals(strings.histogram.chart.tooltipText, { count: this.y.toString() })
                );
            },
            outside: true,
            style: { zIndex: 1000001 },
        },
        time: { timezone: timezone },
    };

    return Highcharts.merge(
        seriesColor ? { colors: [seriesColor] } : undefined,
        options,
        isDarkTheme ? darkTheme : undefined
    );
}

function createSeriesFromData(
    data: HistogramOkValue,
    strings: DataExplorationStrings
): NonNullable<Highcharts.Options['series']> {
    const series: Highcharts.SeriesOptionsType = {
        type: 'area',
        name: strings.histogram.chart.seriesName,
        data: data.map((row) => {
            return {
                x: row.binStart,
                y: row.itemCount,
            };
        }),
    };

    return [series];
}

function createFillColor(
    isDarkTheme: boolean,
    seriesFillColor?: Highcharts.ColorString
): Highcharts.PlotAreaOptions['fillColor'] {
    const defaultColor = seriesFillColor || (isDarkTheme ? darkTheme.colors[0] : Highcharts.getOptions().colors?.[0]);

    if (!defaultColor) {
        return;
    }

    return {
        linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
        },
        stops: [
            [0, defaultColor],
            // @ts-expect-error This format is valid according to the docs but the types want something else
            [1, Highcharts.color(defaultColor).setOpacity(0).get('rgba')],
        ],
    };
}
