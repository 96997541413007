import React, { useMemo, useState } from 'react';
import { Dropdown, Field, Input, Option, Subtitle1 } from '@fluentui/react-components';

import { useStrings } from '../../../hooks/useStrings';
import { translateFilterOperator } from '../../../kqlParser/pillToText';
import {
    BinaryFilterPillData,
    FilterOperator,
    FilterPill,
    FilterPillData,
    isBinaryOperator,
    isTernaryOperator,
    operatorsByType,
    TernaryFilterPillData,
    UNARY_OPERATORS,
    UnaryOperator,
} from '../../../types/Pills.interfaces';
import { ColumnDropdown } from './helpers/ColumnDropdown';
import { FieldLabel } from './helpers/FieldLabel';
import { KqlPreview } from './helpers/KqlPreview/KqlPreview';
import { PopoverButtons } from './helpers/PopoverButtons';

import styles from './Popovers.module.scss';

function isValidUnaryPill(pillData: Partial<FilterPillData>): boolean {
    return UNARY_OPERATORS.includes(pillData.operator as UnaryOperator);
}

function isValidBinaryPill(pillData: Partial<FilterPillData>): boolean {
    return isBinaryOperator(pillData.operator) && !!(pillData as BinaryFilterPillData).value;
}

function isValidTernaryPill(pillData: Partial<FilterPillData>): boolean {
    return (
        isTernaryOperator(pillData.operator) &&
        !!(pillData as TernaryFilterPillData).leftRange &&
        !!(pillData as TernaryFilterPillData).rightRange
    );
}

/** Validate the pill data; return valid pill or undefined */
function validatePill(pillData: Partial<FilterPillData>): FilterPill | undefined {
    if (
        pillData.operator &&
        pillData.column &&
        (isValidUnaryPill(pillData) || isValidBinaryPill(pillData) || isValidTernaryPill(pillData))
    ) {
        return {
            type: 'filter',
            data: {
                ...pillData,
            } as FilterPillData,
        };
    }

    return undefined;
}

export interface FilterPillPopoverProps {
    defaultValues?: Partial<FilterPillData>;
    onApply: (newPill: FilterPill) => void;
    onCancel: () => void;
    /** whether it's a new pill or editing an existing pill */
    newPill?: boolean;
}

export const FilterPillPopover: React.FC<FilterPillPopoverProps> = ({ defaultValues, onApply, onCancel, newPill }) => {
    const { strings } = useStrings();

    const [pillData, setPillData] = useState<Partial<FilterPillData>>({ ...defaultValues });

    const onFormChange = (newState: Partial<FilterPillData>) => {
        setPillData((prevState) => ({ ...prevState, ...newState }));
    };

    const operatorOptions = useMemo(() => {
        if (!pillData.column) {
            return [];
        }

        const operators = operatorsByType[pillData.column.ColumnType];
        return operators.map((operator) => ({
            label: translateFilterOperator(operator, strings),
            value: operator,
        }));
    }, [pillData.column, strings]);

    const validPill = useMemo(() => validatePill(pillData), [pillData]);

    const submitForm = () => {
        if (!validPill) {
            return; // should not get here, button disabled
        }

        onApply(validPill);
    };

    return (
        <>
            <div data-testid="filter-pill-popover" className={styles.filterPopover}>
                <Subtitle1>{strings.dataExplorationModal.pillsBar.filter}</Subtitle1>
                <div className={styles.popoverForm}>
                    {/* Column */}
                    <ColumnDropdown
                        selectedColumn={pillData.column}
                        onOptionSelect={(column) => onFormChange({ column, operator: undefined, value: undefined })}
                        isEditMode={!newPill}
                    />
                    {/* Operator */}
                    <Field label={<FieldLabel text={strings.dataExplorationModal.pillsBar.operator} />}>
                        <Dropdown
                            placeholder={strings.dataExplorationModal.pillsBar.operatorDropdownPlaceholder}
                            onOptionSelect={(e, { optionValue }) =>
                                onFormChange({ operator: optionValue as FilterOperator })
                            }
                            defaultValue={
                                // default label
                                pillData.operator ? translateFilterOperator(pillData.operator, strings) : undefined
                            }
                            defaultSelectedOptions={pillData.operator ? [pillData.operator] : undefined} // default value
                            disabled={!operatorOptions.length}
                            className={styles.popoverField}
                        >
                            {operatorOptions.map((option, i) => (
                                <Option value={option.value} key={i} data-testid={`operator-dropdown-option-${i}`}>
                                    {option.label}
                                </Option>
                            ))}
                        </Dropdown>
                    </Field>
                    {/* Value */}
                    {isBinaryOperator(pillData.operator) ? (
                        <Field label={<FieldLabel text={strings.dataExplorationModal.pillsBar.value} />}>
                            <Input
                                value={(pillData as BinaryFilterPillData).value ?? ''}
                                onChange={(e, { value }) => onFormChange({ value })}
                                className={styles.popoverField}
                            />
                        </Field>
                    ) : null}
                    {/* Range */}
                    {isTernaryOperator(pillData.operator) ? (
                        <div>
                            <Field label={<FieldLabel text={strings.dataExplorationModal.pillsBar.leftRange} />}>
                                <Input
                                    value={(pillData as TernaryFilterPillData).leftRange ?? ''}
                                    onChange={(e, { value }) => onFormChange({ leftRange: value })}
                                    className={styles.popoverField}
                                />
                            </Field>
                            <Field label={<FieldLabel text={strings.dataExplorationModal.pillsBar.rightRange} />}>
                                <Input
                                    value={(pillData as TernaryFilterPillData).rightRange ?? ''}
                                    onChange={(e, { value }) => onFormChange({ rightRange: value })}
                                    className={styles.popoverField}
                                />
                            </Field>
                        </div>
                    ) : null}
                </div>
                <KqlPreview pill={validPill} />
            </div>
            <PopoverButtons onApply={submitForm} onCancel={onCancel} disabled={!validPill} />
        </>
    );
};
