import React, { useCallback } from 'react';
import { mergeClasses } from '@fluentui/react-components';
import { bundleIcon, Edit20Filled, Edit20Regular } from '@fluentui/react-icons';

import { CloseTabButton } from '../CloseTabButton/CloseTabButton';
import { TabItem } from '../Tabs.interfaces';
import { useTabsBarContext } from '../TabsContext/TabsBar.context';
import { ButtonTooltip } from '../TabsTooltip/TabsTooltip';
import { TabText } from '../TabText/TabText';

import styles from './Tab.module.scss';

const Edit = bundleIcon(Edit20Filled, Edit20Regular);

export const Tab: React.FunctionComponent<TabItem> = (tabItem) => {
    const { tabId, icon: Icon } = tabItem;
    const context = useTabsBarContext();
    const isSelected = tabId === context.selectedTabId;
    const isEditing = tabId === context.editingTab.tabId;
    const isSingleTab = context.tabsList.length <= 1;

    const onRename = useCallback(() => {
        context.editingTab.setTabId(tabId);
    }, [tabId, context]);

    return (
        <div
            className={mergeClasses(
                styles.tab,
                isSelected ? mergeClasses(styles.selectedTab, 'selectedTab') : undefined,
                isEditing ? styles.editingTab : undefined,
                isSingleTab ? styles.singleTab : undefined,
                !Icon ? styles.withoutIcon : undefined
            )}
        >
            <div className={styles.tabTextContainer}>
                {Icon && (
                    <Icon filled={isSelected} className={styles.tabIcon} aria-label={context.strings.iconAriaLabel} />
                )}
                <TabText {...tabItem} />
            </div>
            {isEditing ? null : (
                <div className={styles.tabButtons}>
                    <ButtonTooltip
                        tooltip={context.strings.editTab}
                        onClick={onRename}
                        disabled={context.isReadOnly}
                        icon={<Edit />}
                        appearance="transparent"
                        size="small"
                        className={styles.tabButtonEdit}
                        tabIndex={0}
                    />
                    <CloseTabButton disabled={context.isReadOnly} {...tabItem} />
                </div>
            )}
        </div>
    );
};
