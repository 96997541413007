.filterPopover {
    // prevent panel from resizing when fields are added
    min-width: 572px;

    .popoverForm {
        display: flex;
        gap: var(--spacingVerticalL);
        padding-top: var(--spacingVerticalXL);
        align-items: baseline;
    }
}

.aggregationPopover {
    // prevent panel from resizing when fields are added
    min-width: 620px;
    min-height: 300px;

    .aggregationForm {
        display: flex;
        flex-direction: column;
        gap: var(--spacingVerticalL);
        padding-top: var(--spacingVerticalXL);

        .aggregationFormRow {
            display: flex;
            gap: var(--spacingHorizontalL);
            align-items: end;
        }

        .aggregationGroups {
            display: flex;
            flex-direction: column;
            gap: var(--spacingHorizontalM);

            .removeButton {
                height: 32px;
                align-self: flex-end;
            }

            .addButton {
                align-self: flex-start;
            }
        }
    }
}

.popoverField {
    width: 180px;
    min-width: unset !important; // override Fluent's style
    height: 32px;

    :first-child {
        white-space: nowrap;
        overflow: hidden;
    }
}

.formButtons {
    display: flex;
    gap: var(--spacingHorizontalS);
    justify-content: flex-end;
    margin-top: var(--spacingVerticalXXL);
}
