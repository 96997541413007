import { formatLiterals } from '@kusto/utils';

export const generateTableTitle = (
    existingTitle: string | null,
    queryIndex: number,
    newNameTemplate?: string
): string => {
    const isPrimaryResult = existingTitle === 'PrimaryResult';
    const hasName = Boolean(existingTitle);
    const shouldUseExistingName = hasName && !isPrimaryResult;
    if (shouldUseExistingName) return existingTitle as string;

    const defaultTemplate = 'Table ${tableNumber}';
    const template = newNameTemplate || defaultTemplate;
    return formatLiterals(template, {
        tableNumber: (queryIndex + 1).toLocaleString(),
    });
};
