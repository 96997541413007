@use '@kusto/style/tokens';

.highchartsContainer {
    height: 100%;
    min-height: 100px;

    // Wasn't able to find a way to do this in `createHighchartsOptions`. There
    // is a background color setting, but it doesn't appear to support any kind
    // of transparency or "none" option.
    :global(.highcharts-legend-box) {
        fill: none;
    }

    svg text {
        // Highcharts has this set to "none" which means
        // highcharts controls the colors in high contrast mode
        // and that leads to text not respecting the high contrast theme.
        // Setting this to "auto" forces svg <text> to respect the
        // contrast theme.
        //
        // See: https://developer.mozilla.org/en-US/docs/Web/CSS/forced-color-adjust
        forced-color-adjust: auto;
    }
}

.highcharts {
    &__resultContainer {
        display: grid;
        height: 100%;
        overflow-x: hidden;

        &.legend_bottom {
            grid-template-areas:
                'title'
                'chart'
                'legend';

            grid-template-rows: auto 1fr minmax(auto, 126px);
        }
        &.legend_left {
            grid-template-areas:
                'title title'
                'legend chart';

            grid-template-rows: auto 1fr;
            grid-template-columns: minmax(auto, 160px) 1fr;
        }
        &.legend_right {
            grid-template-areas:
                'title title'
                'chart legend';

            grid-template-rows: auto 1fr;
            grid-template-columns: 1fr minmax(auto, 160px);
        }
    }
    &__chart {
        grid-area: chart;
    }
    &__chartsBody {
        display: flex;
        flex: 1;
        flex-direction: column;
        min-height: 0;
        min-width: 0;
    }
    &__chartsBody_h {
        display: flex;
        flex: 1;
        min-width: 0;
    }
    &__chartsBody_hItem {
        display: flex;
        flex: 1 33.333333%;
        flex-direction: column;
        min-width: 0;
    }
    &__horizontalLabel {
        text-align: center;
    }
    &__title {
        grid-area: title;

        font-size: 18px;
        text-align: center;
        margin-bottom: 14px;
    }
    &__customLegend {
        grid-area: legend;
        display: grid;
        justify-items: start;
        overflow: hidden;
        // Using flex or grid properties to align the content will cause the
        // scrollbar to act weird when it overflows left/right. Filling extra
        // space with columns doesn't have this problem.
        grid-template-columns: 1fr auto 1fr;
    }
    &__legendItem {
        grid-column: 2/3;
        padding: 0;
        align-items: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        font-size: 12px;
        font-weight: bold;
        user-select: none;
        text-align: left;
    }
    &__legendSymbol {
        margin-right: 6px;
        width: 10px;
    }
}

.tooltip {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    word-break: break-word;

    svg {
        margin-top: -4px;
    }
}

.seriesLabel {
    align-items: center;
    display: flex;
}

.pointValueLabel {
    font-weight: 600;
}

// Interactive legend

.panelsChartWithLegend {
    display: grid;
    height: 100%;
    overflow-x: hidden;
    .highchartsContainer {
        min-height: unset;
    }
}

.chartWithLegend {
    display: grid;
    height: 100%;
    overflow-x: hidden;
}

.layout_legendRight {
    grid-template-areas: 'chart legend';
    grid-template-columns: 1fr auto;
}

.layout_legendLeft {
    grid-template-areas: 'legend chart';
    grid-template-columns: auto 1fr;
}

.layout_legendBottom {
    grid-template-areas:
        'chart'
        'legend';
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr minmax(0, auto);
}

.chartContainer {
    grid-area: chart;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 0;
    min-width: 0;

    //
    overflow: hidden;
}

.interactiveLegend {
    grid-area: legend;
    display: grid;
    justify-items: start;
    overflow: hidden;
    padding: 2px 4px 2px 0;
}

.leftSideScroll {
    direction: rtl;
}

.seriesName {
    margin-left: 4px;
    font-size: tokens.$fontSizeBase200;
}
