import React from 'react';
import { Caption2 } from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';

import { Theme } from '@kusto/utils';

import { useDataExplorationContext } from '../../../../context/DataExplorationContext';
import {
    HistogramYColumn,
    useHistogram,
    type ColumnWithInsights,
    type DataProfileTimeRange,
} from '../../../../DataExplorationApi';
import { HistogramVisual } from '../../../../Histogram/HistogramVisual';
import { useStrings } from '../../../hooks/useStrings';
import { useDataExplorationStore } from '../../../store/DataExplorationStore';
import { ColumnInfoStatusWrapper } from './ColumnInfoStatusWrapper';

import styles from './SelectedColumnInfo.module.scss';

interface SelectedColumnValueHistogramProps {
    selectedColumn: ColumnWithInsights;
    title: React.ReactNode;
    queryText: string;
    timeRange: DataProfileTimeRange;
    timezone: string;
}

export const SelectedColumnValueHistogram: React.FC<SelectedColumnValueHistogramProps> = observer(
    function SelectedColumnValueHistogram({ selectedColumn, queryText, title, timeRange, timezone }) {
        const { strings } = useStrings();
        const { theme } = useDataExplorationContext();
        const { actions } = useDataExplorationStore();
        const {
            data,
            isLoading: isLoadingHistogram,
            error,
        } = useHistogram(queryText, timeRange, selectedColumn as HistogramYColumn, false, 'unit');

        const chartSelectionEvent: Highcharts.ChartSelectionCallbackFunction = React.useCallback(
            (event) => {
                actions.addPill({
                    type: 'filter',
                    data: {
                        column: { ColumnName: selectedColumn.ColumnName, ColumnType: selectedColumn.ColumnType },
                        operator: 'between',
                        leftRange: `datetime(${new Date(event.xAxis[0].min).toISOString()})`,
                        rightRange: `datetime(${new Date(event.xAxis[0].max).toISOString()})`,
                    },
                });
                return false; // Prevent default behavior - highcharts graph zoom
            },
            [actions, selectedColumn.ColumnName, selectedColumn.ColumnType]
        );

        return (
            <>
                <div className={styles.columnInfoHeader}>
                    {title}
                    <Caption2>{strings.dataExplorationModal.schemaColumns.columnInfo.dragToAddRangeFilter}</Caption2>
                </div>
                <ColumnInfoStatusWrapper isLoading={isLoadingHistogram} errorMessage={error?.message}>
                    <div>
                        <HistogramVisual
                            data={data!}
                            timezone={timezone}
                            isDarkTheme={theme === Theme.Dark}
                            zoomType="x"
                            chartSelectionEvent={chartSelectionEvent}
                        />
                    </div>
                </ColumnInfoStatusWrapper>
            </>
        );
    }
);
